import axios from 'axios';
import config from 'config';
import toast from 'shared/components/andtComponents/Toast';
import { checkIfTokenRefreshNeeded, getValueFromStorage } from 'shared/utils/tokenUtil';

let source = axios.CancelToken.source();
export const cancleApiRequests = () => {
  source.cancel();
  source = axios.CancelToken.source();
};

const formatAndToastError = (error) => {
  const { data } = error.response || {};
  const clientMessage = data?.clientMessage;
  if (clientMessage) {
    toast.error(clientMessage);
  }
};
const processError = (error, api = '', method = '') => {
  // eslint-disable-next-line no-console
  console.log(`Error in api call: ${method || 'no method'} ${api || 'No api'}  ${error}`);
  // if error code = 401 - logout
  if (
    error.response?.status === 401 &&
    !['/v1/users/signout', '/v1/users/signin', '/v1/users/realm'].some(
      (route) => error.request.responseURL.split('/api').pop() === route,
    )
  ) {
    window.handleLogout();
  }
};
const getBaseUrl = (apiName) => {
  switch (apiName) {
    case 'mock':
      return config.mock.apiGateway.URL;
    default:
      return config.apiGateway.URL;
  }
};

const pathIsExcludedFromMemo = (path) => {
  const excluded = import.meta.env.VITE_APP_CACHE_EXCLUDED;
  if (!excluded) {
    return false;
  }
  return excluded.split(',').includes(path);
};
const memoApiCall = async (params, cb) => {
  const path = params[3];
  const key = JSON.stringify(params);
  const inMemory = localStorage.getItem(key);
  if (inMemory && !pathIsExcludedFromMemo(path)) {
    return JSON.parse(inMemory);
  }
  const data = await cb();
  localStorage.setItem(key, JSON.stringify(data));
  return data;
};

export class API {
  /**
   * Make a GET request
   * @param {string} apiName  - The api name of the request
   * @param {string} path - The path of the request
   */
  static get = async (apiName, path) => {
    const getFunction = async (url) => {
      // we need to add the headers to the request before await to apply custom api key (getDataWithCustomApikey)
      const requestConfig = {
        cancelToken: source.token,
        headers: {
          ...config.apiReqHeaders.createHeaders(),
        },
      };
      await checkIfTokenRefreshNeeded();
      requestConfig.headers.Authorization = getValueFromStorage('authToken');
      requestConfig.headers['impersonation-token'] = getValueFromStorage('impersonationToken') || undefined;
      try {
        const response = await axios.get(url, requestConfig);
        return response?.data;
      } catch (e) {
        if (axios.isCancel(e)) {
          return;
        }
        processError(e, url, 'GET');
        throw e;
      }
    };
    try {
      const { apikey, commonParams } = config.apiReqHeaders.createHeaders() || {};
      if (import.meta.env.VITE_APP_CACHE === 'MEMO') {
        return memoApiCall([apikey, commonParams, apiName, path].filter(Boolean), () =>
          getFunction(`${getBaseUrl(apiName)}${path}`),
        );
      }
      return getFunction(`${getBaseUrl(apiName)}${path}`);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`failed GET ${path} with status ${(e.response || {}).status}`);
      throw e;
    }
  };

  /**
   * Make a POST request
   * @param {string} apiName  - The api name of the request
   * @param {string} path - The path of the request
   * @param {json} [payload] - Request payload
   * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
   */
  static post = async (apiName, path, payload = {}) => {
    try {
      const requestConfig = {
        headers: {
          ...config.apiReqHeaders.createHeaders(),
        },
      };
      if (!path.includes('renewToken')) {
        await checkIfTokenRefreshNeeded();
      }
      const response = await axios.post(`${getBaseUrl(apiName)}${path}`, payload?.body, requestConfig);
      return response.data;
    } catch (e) {
      processError(e, path, 'POST');
      formatAndToastError(e);
      throw e;
    }
  };

  /**
   * Make a PUT request
   * @param {string} apiName  - The api name of the request
   * @param {string} path - The path of the request
   * @param {json} [payload] - Request payload
   * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
   */
  static put = async (apiName, path, payload = {}) => {
    try {
      const requestConfig = {
        headers: {
          ...config.apiReqHeaders.createHeaders(),
        },
      };
      await checkIfTokenRefreshNeeded();
      const response = await axios.put(`${getBaseUrl(apiName)}${path}`, payload?.body, requestConfig);
      return response.data;
    } catch (e) {
      processError(e, path, 'PUT');
      formatAndToastError(e);
      throw e;
    }
  };

  /**
   * Make a DELETE request
   * @param {string} apiName  - The api name of the request
   * @param {string} path - The path of the request
   * @param {json} [payload] - Request payload
   * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
   */
  static del = async (apiName, path, payload = {}) => {
    try {
      const requestConfig = {
        headers: {
          ...config.apiReqHeaders.createHeaders(),
        },
      };
      await checkIfTokenRefreshNeeded();
      const response = await axios.delete(`${getBaseUrl(apiName)}${path}`, {
        headers: requestConfig.headers,
        data: payload?.body,
      });
      return response.data;
    } catch (e) {
      processError(e, path, 'DELETE');
      formatAndToastError(e);
      throw e;
    }
  };
}
