import React, { useReducer, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import { useCustomer } from 'shared/hooks/react-query/useDivisions';
import {
  useCreateMultiAccountsCutsomerRole,
  useMultiAccountsResellerCustomerRoles,
} from 'shared/hooks/react-query/useRoles';
import { useUpdateUserRole } from 'shared/hooks/react-query/useUsers';
import Modal from 'shared/components/andtComponents/Modal';
import ErrorMessage from 'shared/components/andtComponents/ErrorMessage';
import FieldFilter from 'shared/components/FieldFilter';
import { USER_TYPES_VALS } from 'users/constants/usersConstants';
import TextInput from './components/TextInput';
import ProcessStatusModal from './components/ProcessStatusModal';
import EditRoleModal from './components/EditRoleModal';
import RoleDivisionReviewTable from './components/RoleDivisionReviewTable';
import CustomerForm from './components/UpdateResellerCustomerRoleForm';

import MultiAccountCustomerRolesTable from './components/MultiAccountCustomerRolesTable';

const propTypes = {
  usersStore: PropTypes.object.isRequired,
};

const initialDiviiosnState = { accountId: '', accountKey: '', divisionId: '' };
const initialRoleDivisions = [];
const creationRoleProcesses = [
  { title: 'Role creation', status: 0 },
  { title: 'Set user role', status: 0 },
];

const MODAL_TYPES = {
  EDIT: 'edit',
  PROCESS: 'process',
};

function diviiosnReducer(state, action) {
  switch (action.field) {
    case 'accountId':
    case 'accountKey':
    case 'divisionId':
      return { ...state, [action.field]: action.value };
    default:
      return { ...state, ...initialDiviiosnState };
  }
}

function roleDivisionsReducer(state, action) {
  const { accountId, accountKey, divisionId } = action.value;
  switch (action.type) {
    case 'add':
      return [...state, action.value];
    case 'remove': {
      const newState = state.filter(
        (division) =>
          division.accountId !== accountId ||
          +division.accountKey !== +accountKey ||
          +division.divisionId !== +divisionId,
      );
      return [...newState];
    }
    default:
      return [];
  }
}

const AdminControlPanel = ({ usersStore }) => {
  const [customerName, setCustomerName] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [modalType, setModalType] = useState('');
  const [onEditRole, setOnEditRole] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [divisionState, divisionDispatcher] = useReducer(diviiosnReducer, initialDiviiosnState);
  const [roleDivisions, roleDivisionDidpatcher] = useReducer(roleDivisionsReducer, initialRoleDivisions);
  const { data: customerData, isLoading: isCustomerDataLoading } = useCustomer(divisionState, {
    retry: false,
    onError: (e) => setErrorMsg(e.response.data.clientMessage),
  });

  const processes = useRef([]);

  const { data: multiAccountsResellerCustomerRoles } = useMultiAccountsResellerCustomerRoles();

  const { mutateAsync: mutationUpdateUserRole } = useUpdateUserRole({
    onSuccess: () => {
      processes.current = processes.current.map((process) => ({ ...process, status: 1 }));
    },
    onError: () => {
      processes.current = processes.current.map((process, idx) => (idx ? { ...process, status: 2 } : { ...process }));
    },
  });

  const { mutateAsync: mutationCreateMultiAccountsCustomerRole } = useCreateMultiAccountsCutsomerRole({
    onSuccess: async (res) => {
      processes.current = processes.current.map((process, idx) => (!idx ? { ...process, status: 1 } : { ...process }));
      await mutationUpdateUserRole({ userKey: selectedUsers[0].value, newRoleId: res.uuid });
    },
    onError: () => {
      processes.current = processes.current.map((process) => ({ ...process, status: 2 }));
    },
  });

  if (usersStore.deprecatedGetCurrentDisplayedUserType !== USER_TYPES_VALS.SUPER_USER) {
    return <Redirect to="/page-not-found" />;
  }
  const customerUsers = usersStore.usersModel.users.map(({ userKey, userName }) => ({
    label: userName,
    value: userKey,
  }));
  const createCustomerRole = async (e) => {
    e.preventDefault();
    const cutEmailDomainRegex = /(?<=@)[^.]*.[^.]*/;
    const selectedUsersDomains = new Set(selectedUsers.map(({ label }) => label.match(cutEmailDomainRegex)[0]));
    if (selectedUsersDomains.size > 1) {
      return;
    }
    processes.current = creationRoleProcesses;
    setModalType(MODAL_TYPES.PROCESS);
    await mutationCreateMultiAccountsCustomerRole({
      customerName,
      accounts: roleDivisions.map((roleDivision) => ({
        accountId: roleDivision.accountId,
        label: roleDivision.accountId,
        linkedAccountIds: roleDivision.linkedAccountIds,
      })),
      linkedDivisions: roleDivisions.map((roleDivision) => ({
        accountId: roleDivision.accountId,
        accountKey: roleDivision.accountKey,
        divisionId: roleDivision.divisionId,
      })),
    });
  };

  const onAddCustomer = (e) => {
    e.preventDefault();
    roleDivisionDidpatcher({ type: 'add', value: customerData });
    divisionDispatcher({});
  };

  const isAddCustomerButtonDisabled =
    !customerData ||
    roleDivisions.find(
      ({ accountId, accountKey, divisionId }) =>
        divisionState.accountId === accountId &&
        +divisionState.accountKey === +accountKey &&
        +divisionState.divisionId === +divisionId,
    );

  const saveRoleDisabled =
    !roleDivisions ||
    !roleDivisions.length ||
    !selectedUsers ||
    !selectedUsers.length ||
    !customerName ||
    !customerName.trim();

  return (
    <Container>
      <PageHeader title={PageNames.ANODOT_ADMIN_PANEL} />
      <div className="mb-2">
        <span className="me-2">Customer Name:</span>
        <TextInput inputValue={customerName} placeholder="Customer Name" onChange={(value) => setCustomerName(value)} />
      </div>
      <CustomerForm
        divisionState={divisionState}
        divisionDispatcher={divisionDispatcher}
        // roleDivisionDidpatcher={roleDivisionDidpatcher}
        onAddCustomer={onAddCustomer}
        // customerData={customerData}
        addCustomerDisabled={isAddCustomerButtonDisabled}
        isCustomerDataLoading={isCustomerDataLoading}
      />
      <div className="d-flex flex-column mb-2">
        <span>Users:</span>
        <FieldFilter
          type="users"
          value={selectedUsers}
          options={customerUsers}
          handleChange={(_, users) => setSelectedUsers(users || [])}
          multi
          isClearable={false}
        />
      </div>
      {roleDivisions && roleDivisions.length ? (
        <RoleDivisionReviewTable
          roleDivisions={roleDivisions}
          onDelete={(value) => roleDivisionDidpatcher({ type: 'remove', value })}
          onSave={createCustomerRole}
          saveButtonDisabled={saveRoleDisabled}
          saveButtonLoading={isCustomerDataLoading}
        />
      ) : null}
      {multiAccountsResellerCustomerRoles && multiAccountsResellerCustomerRoles.length ? (
        <div>
          <span>Multi Accounts Customer Roles:</span>
          <MultiAccountCustomerRolesTable
            rows={multiAccountsResellerCustomerRoles}
            onEditClick={(role) => {
              setOnEditRole(role);
              setModalType(MODAL_TYPES.EDIT);
            }}
            onSyncClick={() => {}} // TODO - add logic for auto sync for existing roles
          />
        </div>
      ) : null}
      <Modal open={!!errorMsg} onClose={() => setErrorMsg('')} title="Error!">
        <ErrorMessage displayError errMsg={errorMsg} />
      </Modal>
      {modalType === MODAL_TYPES.PROCESS ? (
        <ProcessStatusModal
          isOpen={modalType === MODAL_TYPES.PROCESS}
          onClose={() => setModalType('')}
          processes={processes}
        />
      ) : null}
      {modalType === MODAL_TYPES.EDIT ? (
        <EditRoleModal
          role={onEditRole}
          isOpen={modalType === MODAL_TYPES.EDIT}
          onClose={() => {
            setOnEditRole(null);
            setModalType('');
          }}
        />
      ) : null}
    </Container>
  );
};
AdminControlPanel.propTypes = propTypes;
export default observer(AdminControlPanel);
