import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Container, Input, InputGroup, InputGroupText, Row } from 'reactstrap';
import Select from 'react-select';
import { shareSettingsItemTypes } from 'users/constants/usersConstants';
import MultiSelect from 'shared/components/FieldFilter';
import { isEmailValid } from 'shared/utils/strUtil';
import useShareSettings from 'users/hooks/react-query/useShareSettings';
import SlackChannelSelect from 'shared/components/andtComponents/SlackCahnnelSelect/SlackChannelSelect';
import useRoles from 'users/hooks/react-query/useRoles';
import toast from './andtComponents/Toast';
import CustomModal from './andtComponents/Modal';
import Button from './andtComponents/Button';

export const ACCESS_LEVELS = {
  MY_ROLE: 'MY_ROLE',
  CUSTOMERS: 'CUSTOMERS',
  EVERYONE: 'EVERYONE',
  ROLES: 'ROLES',
  USERS: 'USERS',
  ME: 'ME',
};

export const AccessLevelOptions = [
  {
    value: ACCESS_LEVELS.MY_ROLE,
    label: 'Current Role',
  },
  {
    value: ACCESS_LEVELS.ME,
    label: 'Only me',
  },
  {
    value: ACCESS_LEVELS.ROLES,
    label: 'Specific roles',
  },
  {
    value: ACCESS_LEVELS.USERS,
    label: 'Specific users',
  },
  {
    value: ACCESS_LEVELS.CUSTOMERS,
    label: 'Specific customers',
  },
  {
    value: ACCESS_LEVELS.EVERYONE,
    label: 'Everyone',
  },
];

const selectStyles = {
  container: () => ({
    width: '100%',
  }),
  control: (provided) => ({
    ...provided,
    height: 35,
    minHeight: 35,
    alignItems: 'start',
  }),
  menu: (provided) => ({
    ...provided,
    minHeight: 'none',
  }),
};

const getEmailsArray = (emails) =>
  emails
    .split(/,\s?/)
    .map((val) => val.trim())
    .filter(Boolean);

const SaveShareSettingsModal = ({ closeModal, itemId, itemType, isModalOpen, usersStore }) => {
  const [id, setId] = useState(null);
  const [level, setLevel] = useState(ACCESS_LEVELS.MY_ROLE);
  const [customers, setCustomers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [emails, setEmails] = useState('');
  const [slackChannels, setSlackChannels] = useState([]);

  const { fetchShareSettingById, deleteShareSetting, updateShareSetting, addShareSetting } = useShareSettings();
  const { data: settingFromDb, isFetching: settingLoading } = fetchShareSettingById(itemId, {
    enabled: isModalOpen,
  });
  const { fetchRoles } = useRoles();
  const { data: rolesData = [] } = fetchRoles({
    enabled: isModalOpen,
  });

  useEffect(() => {
    if (settingLoading || !settingFromDb) {
      return;
    }
    setLevel(settingFromDb.level);
    setId(settingFromDb.uuid);
    setRoles(settingFromDb.roles || []);
    setCustomers(settingFromDb.customers || []);
    setEmails(settingFromDb.emails ? settingFromDb.emails.join(', ') : '');
    setSlackChannels(settingFromDb.slackChannels || []);
  }, [settingLoading]);

  const handleSlackSelect = (channels) => {
    if (!channels) {
      setSlackChannels([]);
      return;
    }
    const formatedChannels = channels.map((channel) => ({ id: channel.value, name: channel.label }));
    setSlackChannels(formatedChannels);
  };

  const handleDeleteShareSetting = () => {
    const { rootStore } = usersStore;
    deleteShareSetting.mutate({ id });
    rootStore.usageStore.customDbSubStore.customDashboardModel.setDashboardSetting(itemId, null);
    closeModal();
    toast.success('Shared Setting Deleted');
  };
  const saveShareSetting = () => {
    const { rootStore } = usersStore;
    if (level === ACCESS_LEVELS.MY_ROLE) {
      handleDeleteShareSetting();
      return;
    }
    const data = {
      level,
      itemId,
      itemType,
      ...(level === ACCESS_LEVELS.ROLES ? { roles } : {}),
      ...(level === ACCESS_LEVELS.CUSTOMERS ? { customers } : {}),
      ...(level === ACCESS_LEVELS.USERS
        ? {
            emails: getEmailsArray(emails),
            slackChannels,
          }
        : {}),
    };
    if (id) {
      updateShareSetting.mutate({ id, body: data });
    } else {
      addShareSetting.mutate({ body: data });
    }
    rootStore.usageStore.customDbSubStore.customDashboardModel.setDashboardSetting(itemId, data);
    toast.success('Shared Setting Saved');
    closeModal();
  };
  const allRoles = [...rolesData];
  const { divisionList: allCustomers } = usersStore.usersModel;
  const rolesOptions = allRoles?.map((role) => ({
    value: role.uuid,
    label: role.roleName,
  }));
  const customersOptions = allCustomers.map((customer) => ({
    value: customer.divisionId,
    label: customer.divisionName,
  }));

  const rolesEmptyWhenSelected = level === ACCESS_LEVELS.ROLES && roles.length === 0;
  const customersEmptyWhenSelected = level === ACCESS_LEVELS.CUSTOMERS && customers.length === 0;
  const emailsEmptyWhenSelected = level === ACCESS_LEVELS.USERS && emails === '';
  const emailsWrongFormat =
    level === ACCESS_LEVELS.USERS && getEmailsArray(emails).some((email) => !isEmailValid(email));

  return (
    <CustomModal
      open={isModalOpen}
      overrideStyles={{ width: 800 }}
      onClose={closeModal}
      title="Share Settings"
      saveDisabled={
        rolesEmptyWhenSelected || emailsEmptyWhenSelected || emailsWrongFormat || customersEmptyWhenSelected
      }
      onSave={saveShareSetting}
      leftButton={<Button isError onClick={handleDeleteShareSetting} text="Set Default" />}
    >
      {settingLoading ? (
        'Loading...'
      ) : (
        <Container>
          <Row className="mb-4 position-relative">
            <Select
              value={AccessLevelOptions.find((opt) => opt.value === level)}
              options={AccessLevelOptions.filter((opt) => {
                if (opt.value === ACCESS_LEVELS.CUSTOMERS && !usersStore.isCurrentUserReseller) {
                  return false;
                }
                return (
                  ![ACCESS_LEVELS.ROLES, ACCESS_LEVELS.EVERYONE].includes(opt.value) ||
                  (!usersStore.isCurrentUserResellerCustomer && !usersStore.isCurrentUserResellerCustomerEdp)
                );
              })}
              styles={selectStyles}
              onChange={(item) => {
                setLevel(item.value);
                setEmails('');
                setRoles([]);
                setCustomers([]);
              }}
              placeHolder="Select access level"
              label="Content can be seen by:"
            />
          </Row>
          {level === ACCESS_LEVELS.USERS && (
            <>
              <Row>
                <InputGroup size="sm">
                  <InputGroupText addonType="prepend">Emails</InputGroupText>
                  <Input
                    type="email"
                    bsSize="sm"
                    className="general-input"
                    placeholder="example@pileus.com, example1@pileus.com"
                    onChange={(e) => setEmails(e.target.value)}
                    value={emails}
                    autocomplete="savemodaluseremail"
                  />
                </InputGroup>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <InputGroup size="sm">
                  <SlackChannelSelect
                    savedChannels={slackChannels}
                    onChangeHandler={handleSlackSelect}
                    id="share_settings"
                  >
                    <InputGroupText addonType="prepend">Slack Channels</InputGroupText>
                  </SlackChannelSelect>
                </InputGroup>
              </Row>
            </>
          )}
          {level === ACCESS_LEVELS.ROLES && (
            <Row className="position-relative">
              <MultiSelect
                type=""
                styles={selectStyles}
                style={{
                  width: '100%',
                }}
                value={rolesOptions?.filter((opt) => roles.includes(opt.value))}
                options={rolesOptions}
                handleChange={(type, values) => {
                  setRoles(values ? values.map((v) => v.value) : []);
                }}
              />
            </Row>
          )}
          {level === ACCESS_LEVELS.CUSTOMERS && (
            <Row className="position-relative">
              <MultiSelect
                type=""
                styles={selectStyles}
                style={{
                  width: '100%',
                }}
                value={customersOptions.filter((opt) => customers.includes(opt.value))}
                options={customersOptions}
                handleChange={(type, values) => {
                  setCustomers(values ? values.map((v) => v.value) : []);
                }}
              />
            </Row>
          )}
        </Container>
      )}
      {rolesEmptyWhenSelected && <p className="text-danger text-left">Roles are empty</p>}
      {customersEmptyWhenSelected && <p className="text-danger text-left">Customers are empty</p>}
      {emailsEmptyWhenSelected && <p className="text-danger text-left">Emails are empty</p>}
      {emailsWrongFormat && <p className="text-danger text-left">Emails are wrong</p>}
    </CustomModal>
  );
};

SaveShareSettingsModal.propTypes = {
  usersStore: PropTypes.object.isRequired,
  itemId: PropTypes.string.isRequired,
  itemType: PropTypes.oneOf(Object.values(shareSettingsItemTypes)).isRequired,
  closeModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
};

export default observer(SaveShareSettingsModal);
