import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PreferencesKeys, PreferencesTypes, UsersType } from 'users/constants/usersConstants';
import DraggableList from 'shared/components/tables/DraggableList/index';
import OneChoiceFieldFilter from 'shared/components/OneChoiceFieldFilter';
import Spinner from 'shared/components/andtComponents/Spinner';
import { discountTypesToDisplayNamesMap } from 'divisions/constants/customersConstants';
import { usePreferences } from 'users/hooks/react-query/usePreferences';
import toast from 'shared/components/andtComponents/Toast';
import Button from 'shared/components/andtComponents/Button';
import TableHeader from 'shared/components/tableHeader/TableHeader';
import { TableHeaderProvider } from 'shared/components/tableHeader/TableHeaderContext';
import { formatDateTime } from 'shared/utils/dateUtil';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import styles from './PreferencesTableDraggable.module.scss';

const PreferencesTableDraggable = ({ usersStore, isAllAccounts, payerAccountOptions }) => {
  const [preferences, setPreferences] = useState();
  const [modifiedPref, setModifiedPref] = useState(null);
  const [payerAccount, setPayerAccount] = useState(payerAccountOptions[0].value);

  const { fetchPreferencesByTypeAndKeyWithTenant, useSaveMutation } = usePreferences();
  const handleSave = useSaveMutation();
  const { data, isLoading } = fetchPreferencesByTypeAndKeyWithTenant(
    usersStore.currDispUserAccountKey,
    PreferencesTypes.RESELLER,
    PreferencesKeys.REBILLING_RULES,
  );

  const getUpdatedDetails = (pref) => {
    const updatedBy = (pref || {}).createdBy || 'Default';
    const updateDate = (pref || {}).updateTime || null;
    return { updatedBy, updateDate };
  };
  const createMockPref = (pref) => {
    if (!pref) {
      return [];
    }
    const modPref = Object.entries(pref.preferences.calculationOrder).map(([k, v]) => ({
      preferenceKey: k,
      uuid: `${pref.uuid}-${k}`,
      preferences: {
        calculationOrder: v,
      },
    }));
    return modPref;
  };

  useEffect(() => {
    if (data) {
      setPreferences(data);
      setModifiedPref(createMockPref(data[payerAccount]));
    }
  }, [data]);

  const updatePreferencesOrder = (newOrder) => {
    const newModifiedPref = modifiedPref.map((p) => {
      if (!newOrder.map(({ uuid }) => uuid).includes(p.uuid)) {
        return p;
      }
      const newIndex = newOrder.findIndex((o) => o.uuid === p.uuid);
      return {
        ...p,
        preferences: {
          calculationOrder: newIndex + 1,
        },
      };
    });
    setModifiedPref(newModifiedPref);
  };
  const modifyToOrigStructure = (modifiedPref) => {
    const calcOrderObj = modifiedPref.reduce((acc, curr) => {
      acc[curr.preferenceKey] = curr.preferences.calculationOrder;
      return acc;
    }, {});
    const preference = preferences[payerAccount];
    preference.preferences.calculationOrder = calcOrderObj;
    return preference;
  };
  const handleSavePreferences = async () => {
    const origStructurePref = modifyToOrigStructure(modifiedPref);
    await handleSave.mutateAsync([{ ...origStructurePref, updated: 1, accountId: payerAccount }]);
    toast.success('Changes Saved');
  };
  const handleOrderChange = (fIndex, sIndex, tableData) => {
    const newOrder = [...tableData];
    const fPanel = tableData[fIndex];
    newOrder.splice(fIndex, 1);
    newOrder.splice(sIndex, 0, fPanel);
    updatePreferencesOrder(newOrder);
  };
  const handlePayerAccountChange = (value) => {
    setPayerAccount(value);
    setModifiedPref(createMockPref(preferences[value]));
  };
  const renderTableRow = (row, dragProps, _, index) => {
    const { uuid, preferenceKey } = row;
    return (
      <div className="pb-2">
        <div className={`p-2 border rounded-2 pe-4 d-flex justify-content-between ${styles.rowContainer}`}>
          <span key={`${uuid}`} {...dragProps} className={styles.row}>
            <GenerateIcon iconName={ICONS.gripDots.name} className={`me-2 ml-n2  ${styles.rowIcon}`} />
            {discountTypesToDisplayNamesMap.get(preferenceKey) || preferenceKey}
          </span>
          {index + 1}
        </div>
      </div>
    );
  };
  const sortedPreferences = (modifiedPref || []).sort(
    (a, b) => Number(a.preferences.calculationOrder) - Number(b.preferences.calculationOrder),
  );
  const actionsDisabled = ![UsersType.USER, UsersType.RESELLER].includes(
    Number(usersStore.deprecatedGetCurrentDisplayedUserType),
  );
  const { updatedBy, updateDate } = getUpdatedDetails(preferences ? preferences[payerAccount] : null);
  // render date time in one row because they are the same for all rows
  return (
    <div className="w-100">
      <div className="mb-4">
        <TableHeaderProvider>
          <TableHeader
            tableName="Billing Rules Calculation Order"
            hideSearch
            isCreatable={false}
            titleInfo={
              <div className={styles.headerInfo}>
                <div>Created by: {updatedBy}</div>
                {updateDate && <div>Updated: {formatDateTime(updateDate)}</div>}
              </div>
            }
          />
        </TableHeaderProvider>
      </div>
      {isAllAccounts ? (
        <div className="mb-4">
          <h5 className="">Payer Account</h5>
          <OneChoiceFieldFilter
            type="payerAccount"
            value={payerAccountOptions.find((opt) => opt.value === payerAccount)}
            options={payerAccountOptions}
            handleChange={(type, value) => handlePayerAccountChange(value.value)}
            isClearable
          />
        </div>
      ) : null}
      {modifiedPref ? (
        <>
          <DraggableList
            items={sortedPreferences}
            onChangeOrder={(fIndex, sIndex) => handleOrderChange(fIndex, sIndex, modifiedPref)}
            renderItem={renderTableRow}
            columns={['Margin Types', 'Rank']}
          />
          <div className="d-flex pe-3 pt-3">
            <Button
              className="mb-4"
              disabled={actionsDisabled || handleSave.isLoading || isLoading}
              onClick={handleSavePreferences}
              text="Save"
            />
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

PreferencesTableDraggable.propTypes = {
  usersStore: PropTypes.object.isRequired,
  isAllAccounts: PropTypes.bool.isRequired,
  payerAccountOptions: PropTypes.array.isRequired,
};

export default PreferencesTableDraggable;
