import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppliedRoute from 'app/containers/App/components/AppliedRoute';
import UnauthenticatedRoute from 'app/containers/App/components/UnauthenticatedRoute';
import AuthenticatedRoute from 'app/containers/App/components/AuthenticatedRoute';
import Layout from 'app/containers/Layout/index';
import MainWrapper from 'app/containers/App/MainWrapper';
import AdminAccountStatus from 'admin/containers/AccountStatus';
import LogIn from 'users/containers/LogIn/Login';
import ResetPassword from 'users/containers/LogIn/ResetPassword';
import Register from 'users/containers/Register';
import AccountPage from 'users/containers/Account';
import OrganizationPage from 'users/containers/Organization/Organization';
import FeatureFlagsPage from 'users/containers/FeatureFlags';
import AzureCustomersPage from 'divisions/containers/Customers/azureCustomers';
import AwsCustomersPage from 'divisions/containers/Customers/awsCustomers';
import BillingSummaryPage from 'divisions/containers/BilingSummary';
import CustomersHistory from 'divisions/containers/CustomersHistory';
import ManageCustomersPage from 'divisions/containers/ManageCustomers';
import CustomersReports from 'divisions/containers/Customers/reports';
import DashboardPage from 'app/containers/Dashboard';
import OnboardingWelcome from 'app/containers/OnboardingWelcome';
import OnboardingAws from 'app/containers/Onboarding/AwsOnboarding';
import OnboardingAzure from 'app/containers/Onboarding/AzureOnboarding';
import OnboardingGcp from 'app/containers/Onboarding/GcpOnboarding';
import NotFoundPage from 'app/containers/NotFound/index';
import WelcomePage from 'app/containers/Welcome/index';
import LandingPage from 'app/containers/Landing/Landing';
import NotAllowedPage from 'app/containers/NotAllowed/NotAllowed';
import Ec2UsagePage from 'usage/containers/Ec2Usage';
import RdsUsagePage from 'usage/containers/RdsUsage';
import S3UsagePage from 'usage/containers/S3Usage';
import Budget from 'usage/containers/Budget';
import AssetsPage from 'usage/containers/Assets';
import DesignSystem from 'shared/components/designSystem/DesignSystem';
import CostTracking from 'usage/containers/CostAndUsageExplorer';
import MetricsExplorerPage from 'usage/containers/MetricsExplorer';
import CustomDashboard from 'usage/containers/CustomDashboard';
import CustomDashboardsPanels from 'usage/containers/CustomDashboardsPanels';
import CustomDashboardWrapper from 'usage/containers/CustomDashboard/components/CustomDashboardWrapper';
import VirtualTags from 'usage/containers/VirtualTags';
import FilterGroup from 'usage/containers/FilterGroup';
import BusinessMapping from 'usage/containers/BusinessMapping';
import BusinessMappingGroups from 'usage/containers/BusinessMappingGroups';
import BusinessMappingGlobalGroups from 'usage/containers/BusinessMappingGlobalGroups';
import AccountTags from 'usage/containers/AccountTags';
import Views from 'usage/containers/Views';
import AnomalyDetection from 'usage/containers/AnomalyDetection';
import ResourceExplorer from 'usage/containers/ResourceExplorer';
import BigQuery from 'usage/containers/BigQuery';
import KubernetesDashboard from 'kubernetes/containers/KubernetesDashboard';
import KubernetesPreview from 'kubernetes/containers/KubernetesPreview';
import KubernetesPreferences from 'kubernetes/containers/Preferences';
import GcpAppEngine from 'usage/containers/GcpAppEngine';
import GcpCloudSQL from 'usage/containers/GcpCloudSQL';
import GcpComputeEngine from 'usage/containers/GcpComputeEngine';
import AzureVirtualMachines from 'usage/containers/AzureVirtualMachines/AzureVirtualMachines';
import AzureStorage from 'usage/containers/AzureStorage/AzureStorage';
import AzureDbCosts from 'usage/containers/AzureDbCosts/AzureDbCosts';
import AzureDataFactory from 'usage/containers/AzureDataFactory';
import Reports from 'usage/containers/Reports/index';
import SavingsPlansAnalyzer from 'commitment/containers/SpAnalyzer';
import RiUtilizationPage from 'commitment/containers/Commitments/RiUtilizationPage';
import CommitmentDashboardPage from 'commitment/containers/Dashboard/CommitmentDashboardPage';
import SavingsPlansUtilization from 'commitment/containers/Commitments/SpUtilizationPage';
import IdleInstancesPage from 'usage/containers/IdleInstances/index';
import CostAndUsageAlerts from 'usage/containers/Alerts/index';
import RecommendationPreferences from 'recommendations/containers/RecommendationsPreferences';
import RecommendationReports from 'recommendations/containers/Reports';
import SlackLandingPage from 'shared/components/slackIntegration/SlackLandingPage';
import { Routes } from 'shared/constants/routes';
import AwsBillingRules from 'divisions/containers/Customers/AwsBillingRules';
import AzureBillingRules from 'divisions/containers/Customers/AzureBillingRules';
import BillingRulesNew from 'divisions/containers/BillingRulesNew';
import RebillingPreferences from 'divisions/containers/Preferences';
import RecommendationTrial from 'recommendations/containers/TrialRecPage';
import AwsCreditsNew from 'divisions/containers/Credits/index';
import { checkTrialUser } from 'shared/components/CheckTrialUser';
import AzureMCAMigration from 'users/containers/Onboarding/azure/components/AzureMCAMigration';
import AnodotAdminControlPanel from 'anodot-admin-panel/index';
import { CostGpt } from 'cost-gpt';
import RecommendationsNewRoot from 'recommendationsNew/menuItems/root';
import SpAnalyzerNew from 'commitment/containers/spAnalyzerNew';
import { PermissionsContextProvider } from 'app/contexts/PermissionsContext';

const SnowConfig = {
  name: 'snow-theme',
  companyName: 'Snow',
  privacyURL: 'https://www.snowsoftware.com/legal/privacy-policy/',
  termsUrl: 'https://www.snowsoftware.com/legal/tos/',
  contactUrl: null,
};

const Router = ({ childProps }) => (
  <MainWrapper childProps={childProps}>
    <main>
      <Switch>
        <UnauthenticatedRoute exact path={Routes.LOG_IN} component={LogIn} props={childProps} />
        {/* need for SSO login */}
        <UnauthenticatedRoute
          exact
          path="/"
          component={() => <Redirect to={`${Routes.LOG_IN}${window.location.hash}`} />}
          props={childProps}
        />
        <UnauthenticatedRoute exact path={Routes.RESET_PASSWORD} component={ResetPassword} props={childProps} />
        <AppliedRoute exact path={Routes.DESIGN_SYSTEM} component={DesignSystem} props={childProps} />
        <AppliedRoute exact path={Routes.REGISTER} component={Register} props={childProps} />
        <AppliedRoute
          exact
          path={Routes.REGISTER_NEW_USER_MANAGEMENT}
          component={Register}
          props={{ ...childProps, isNewUserManagement: true }}
        />
        <AppliedRoute exact path={Routes.REGISTER_MSP} component={Register} props={{ ...childProps, isMSP: true }} />
        <AppliedRoute
          exact
          path={Routes.REGISTER_SNOW}
          component={Register}
          props={{ ...childProps, theme: SnowConfig }}
        />
        <AppliedRoute
          exact
          path={Routes.REGISTER_SNOW_MSP}
          component={Register}
          props={{ ...childProps, theme: SnowConfig, isMSP: true }}
        />
        <PermissionsContextProvider usersStore={childProps.usersStore}>
          <div>
            <Layout childProps={childProps} />
            <div className="container__wrap">
              <Switch>
                <AppliedRoute exact path={Routes.SLACK_INTEGRATION} component={SlackLandingPage} props={childProps} />
                <AuthenticatedRoute
                  path={Routes.ACCOUNT}
                  component={childProps.appStore.isKeyCloakManagement ? OrganizationPage : AccountPage}
                  props={childProps}
                />
                <AuthenticatedRoute exact path={Routes.FEATURE_FLAGS} component={FeatureFlagsPage} props={childProps} />
                <AuthenticatedRoute
                  exact
                  path={Routes.ACCOUNTS_OVERVIEW}
                  component={OnboardingWelcome}
                  props={childProps}
                />
                <AuthenticatedRoute exact path={Routes.ONBOARDING_AWS} component={OnboardingAws} props={childProps} />
                <AuthenticatedRoute
                  exact
                  path={Routes.ONBOARDING_AZURE}
                  component={OnboardingAzure}
                  props={childProps}
                />
                <AuthenticatedRoute exact path={Routes.ONBOARDING_GCP} component={OnboardingGcp} props={childProps} />
                <AuthenticatedRoute exact path={Routes.DASHBOARD} component={DashboardPage} props={childProps} />
                <AuthenticatedRoute exact path={Routes.WELCOME} component={WelcomePage} props={childProps} />
                <AuthenticatedRoute exact path={Routes.LANDING} component={LandingPage} props={childProps} />
                <AuthenticatedRoute exact path={Routes.NOT_ALLOWED} component={NotAllowedPage} props={childProps} />
                <AuthenticatedRoute exact path={Routes.EC2} component={Ec2UsagePage} props={childProps} />
                <AuthenticatedRoute exact path={Routes.RDS} component={RdsUsagePage} props={childProps} />
                <AuthenticatedRoute exact path={Routes.S3} component={S3UsagePage} props={childProps} />
                <AuthenticatedRoute exact path={Routes.REPORTS} component={Reports} props={childProps} />
                <AuthenticatedRoute
                  exact
                  path={Routes.RI_UTILIZATION}
                  component={RiUtilizationPage}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.COMMITMENT_DASHBOARD}
                  component={CommitmentDashboardPage}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.SAVINGS_PLANS_UTILIZATION}
                  component={SavingsPlansUtilization}
                  props={childProps}
                />
                <AuthenticatedRoute
                  path={Routes.RECOMMENDATION_EXPLORER}
                  component={checkTrialUser(RecommendationsNewRoot, RecommendationTrial, childProps)}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.RECOMMENDATION_PREFERENCES}
                  component={checkTrialUser(RecommendationPreferences, RecommendationTrial, childProps)}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.RECOMMENDATION_REPORTS}
                  component={checkTrialUser(RecommendationReports, RecommendationTrial, childProps)}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.BUDGET}
                  component={Budget}
                  props={childProps}
                  remountKey={() => Routes.BUDGET}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.COST_USAGE_EXPLORER}
                  component={CostTracking}
                  remountKey={() => Routes.COST_USAGE_EXPLORER}
                  blockResource={[(prop) => prop.invoiceStore.isDistinctValuesLoading]}
                  observeResources={[
                    (prop) => prop.invoiceStore.isDistinctValuesLoading,
                    (prop) => prop.appStore.isPpApplied,
                  ]}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.METRICS_EXPLORER}
                  component={MetricsExplorerPage}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.CUSTOM_DASHBOARD_INSTANCE}
                  component={CustomDashboardWrapper}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.CUSTOM_DASHBOARD_TEMPLATE_INSTANCE}
                  component={CustomDashboardWrapper}
                  props={{ ...childProps, isTemplate: true }}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.CUSTOM_DASHBOARD}
                  component={CustomDashboard}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.CUSTOM_DASHBOARDS_PANELS}
                  component={CustomDashboardsPanels}
                  props={childProps}
                />
                <AuthenticatedRoute exact path={Routes.VIRTUAL_TAGS} component={VirtualTags} props={childProps} />
                <AuthenticatedRoute exact path={Routes.FILTER_GROUP} component={FilterGroup} props={childProps} />
                <AuthenticatedRoute
                  exact
                  path={Routes.BUSINESS_MAPPING_GLOBAL_GROUPS}
                  component={BusinessMappingGlobalGroups}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.BUSINESS_MAPPING_GROUPS}
                  component={BusinessMappingGroups}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.BUSINESS_MAPPING}
                  component={BusinessMapping}
                  props={childProps}
                />
                <AuthenticatedRoute exact path={Routes.ACCOUNT_TAGS} component={AccountTags} props={childProps} />
                <AuthenticatedRoute exact path={Routes.VIEWS} component={Views} props={childProps} />
                <AuthenticatedRoute
                  exact
                  path={Routes.ANOMALY_DETECTION}
                  component={AnomalyDetection}
                  props={childProps}
                  remountKey={() => Routes.ANOMALY_DETECTION}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.RESOURCE_EXPLORER}
                  component={ResourceExplorer}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.SAVINGS_PLANS_ANALYZER}
                  component={checkTrialUser(SavingsPlansAnalyzer, RecommendationTrial, childProps)}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.SAVINGS_PLANS_ANALYZER_NEW}
                  component={SpAnalyzerNew}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.SAVINGS_PLANS_ANALYZER_REC}
                  component={SavingsPlansAnalyzer}
                  props={childProps}
                />
                <AuthenticatedRoute exact path={Routes.BIG_QUERY} component={BigQuery} props={childProps} />
                <AuthenticatedRoute exact path={Routes.GcpAppEngine} component={GcpAppEngine} props={childProps} />
                <AuthenticatedRoute exact path={Routes.GcpCloudSQL} component={GcpCloudSQL} props={childProps} />
                <AuthenticatedRoute
                  exact
                  path={Routes.GcpComputeEngine}
                  component={GcpComputeEngine}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.AZURE_VIRTUAL_MACHINES}
                  component={AzureVirtualMachines}
                  props={childProps}
                />
                <AuthenticatedRoute exact path={Routes.AZURE_STORAGE} component={AzureStorage} props={childProps} />
                <AuthenticatedRoute exact path={Routes.AZURE_DB_COSTS} component={AzureDbCosts} props={childProps} />
                <AuthenticatedRoute
                  exact
                  path={Routes.AzureDataFactory}
                  component={AzureDataFactory}
                  props={childProps}
                />
                <AuthenticatedRoute exact path={Routes.K8S_PREVIEW} component={KubernetesPreview} props={childProps} />
                <AuthenticatedRoute
                  exact
                  path={Routes.K8S_USAGE}
                  remountKey={() => Routes.K8S_USAGE}
                  blockResource={[(prop) => prop.invoiceStore.isDistinctValuesLoading]}
                  observeResources={[
                    (prop) => prop.invoiceStore.isDistinctValuesLoading,
                    (prop) => prop.appStore.isPpApplied,
                  ]}
                  component={KubernetesDashboard}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.K8S_PREFERENCES}
                  component={KubernetesPreferences}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.K8S_ASSETS}
                  component={AssetsPage}
                  blockResource={[(prop) => prop.invoiceStore.isDistinctValuesLoading]}
                  observeResources={[(prop) => prop.invoiceStore.isDistinctValuesLoading]}
                  props={{
                    ...childProps,
                    isK8S: true,
                  }}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.AZURE_CUSTOMERS}
                  component={AzureCustomersPage}
                  props={childProps}
                />
                <AuthenticatedRoute exact path={Routes.AWS_CUSTOMERS} component={AwsCustomersPage} props={childProps} />
                <AuthenticatedRoute
                  exact
                  path={Routes.BILLING_SUMMARY}
                  component={BillingSummaryPage}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.CUSTOMERS_HISTORY}
                  component={CustomersHistory}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.CUSTOMERS_MANAGE}
                  component={ManageCustomersPage}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.CUSTOMERS_REPORTS}
                  component={CustomersReports}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.BILLING_RULES_NEW}
                  component={BillingRulesNew}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.AWS_BILLING_RULES}
                  component={AwsBillingRules}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.AZURE_BILLING_RULES}
                  component={AzureBillingRules}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.REBILLING_PREFERENCES}
                  component={RebillingPreferences}
                  props={childProps}
                />
                <AuthenticatedRoute exact path={Routes.AWS_CREDITS_NEW} component={AwsCreditsNew} props={childProps} />
                <AuthenticatedRoute
                  exact
                  path={Routes.IDLE_INSTANCES}
                  component={IdleInstancesPage}
                  props={childProps}
                />
                <AuthenticatedRoute exact path={Routes.CUE_ALERTS} component={CostAndUsageAlerts} props={childProps} />
                <AuthenticatedRoute
                  exact
                  path={Routes.ADMIN_ACCOUNTS_STATUS}
                  component={AdminAccountStatus}
                  props={childProps}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.ASSETS}
                  component={AssetsPage}
                  props={childProps}
                  blockResource={[(prop) => prop.invoiceStore.isDistinctValuesLoading]}
                  observeResources={[(prop) => prop.invoiceStore.isDistinctValuesLoading]}
                />
                <AuthenticatedRoute
                  exact
                  path={Routes.ADMIN_CONTROL_PANEL}
                  component={AnodotAdminControlPanel}
                  props={childProps}
                />
                <AuthenticatedRoute exact path={Routes.COST_GPT} component={CostGpt} props={childProps} />
                {/* Finally, catch all unmatched routes */}
                <AuthenticatedRoute component={NotFoundPage} props={childProps} />
              </Switch>
            </div>
          </div>
        </PermissionsContextProvider>
      </Switch>
    </main>
    <AzureMCAMigration />
  </MainWrapper>
);

Router.propTypes = {
  childProps: PropTypes.object.isRequired,
};

export default Router;
