import { useQuery } from 'react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import { AWS_RECOMMENDATIONS_ROOT, BILLINGS_API_NAME } from 'recommendations/hooks/react-query/api';
import { RecommendationStatus } from 'recommendations/constants/recommendationsConstants';
import { UsersType } from 'users/constants/usersConstants';
import { queryClient } from 'queryClient';
import { toast } from 'react-toastify';
import { prepareRecommendations } from 'recommendations/hooks/react-query/recommendationHelpers';

const updateRecommendationStatus = (recommendationType, signatures, status) => {
  const body = { signatures };
  return API.put('billings', `/api/v1/recommendations/${recommendationType}/update-status/${status}`, { body });
};

const setRecommendationsStatus = async (recommendations, status) => {
  const selectedRecs = Array.isArray(recommendations) ? recommendations : [recommendations];
  if (selectedRecs?.length === 0) {
    return;
  }
  // Only recs with one type can be updated
  if ([...new Set(selectedRecs.map((rec) => rec.type))].length > 1) {
    return;
  }
  try {
    await updateRecommendationStatus(
      selectedRecs[0].type,
      selectedRecs.map((r) => r.signature),
      status,
    );
  } catch {
    toast.error('Something went wrong please try again later');
  }
};

const selectOpenRecommendations = (
  recommendations,
  deprecatedGetCurrentDisplayedUserType,
  mapLinkedAccIdToDivisionName,
) => {
  let tmpRecommendations = recommendations?.filter((rec) => rec.status === RecommendationStatus.OPEN);
  if (deprecatedGetCurrentDisplayedUserType === UsersType.RESELLER) {
    tmpRecommendations = tmpRecommendations.map((rec) => ({
      ...rec,
      customer: mapLinkedAccIdToDivisionName.get(rec.linkedAccountId) || '',
    }));
  }
  return tmpRecommendations;
};

const fetchRecommendationByType = async (type, limit) =>
  API.get(
    BILLINGS_API_NAME,
    `${AWS_RECOMMENDATIONS_ROOT}/i${type || limit ? '?' : ''}${type ? `recType=${type}` : ''}${
      limit ? `&limit=${limit}` : ''
    }`,
  );

export const fetchRecommendationDataByType = async (
  type,
  deprecatedGetCurrentDisplayedUserType,
  mapLinkedAccIdToDivisionName,
  limit,
) => {
  const rawRecommendations = await fetchRecommendationByType(type, limit);
  const preparedRecommendations = prepareRecommendations(rawRecommendations);
  return selectOpenRecommendations(
    preparedRecommendations,
    deprecatedGetCurrentDisplayedUserType,
    mapLinkedAccIdToDivisionName,
  );
};

export const excludeRecommendations = (recType, signatures, status, excludeData, excludeMessageMap) => {
  const body = {
    signatures,
    status,
    excludeData,
    excludeMessageMap,
  };
  return API.put('billings', `/api/v1/recommendations/${recType}/exclude-recomms`, { body });
};

const excludeRecommendationHelper = async (recommendations, excludeData, excludeMessageMap) => {
  const selectedRecs = Array.isArray(recommendations) ? recommendations : [recommendations];
  if (selectedRecs?.length === 0) {
    return;
  }
  // Only recs with one type can be updated
  if ([...new Set(selectedRecs.map((rec) => rec.type))].length > 1) {
    return;
  }
  await excludeRecommendations(
    selectedRecs[0].type,
    selectedRecs.map((rec) => rec.signature),
    RecommendationStatus.EXCLUDED,
    excludeData,
    excludeMessageMap,
  );
};

export const addRecommendationsToMondayBoard = async (recommendations) =>
  API.post('billings', '/api/v1/integrations/monday/add-item-in-board', { body: { recommendations } });

export const useRecommendationByType = ({
  isEnabled,
  recommendationType,
  deprecatedGetCurrentDisplayedUserType,
  mapLinkedAccIdToDivisionName,
}) => {
  const queryKey = [apiConstants.QUERY_KEYS.RECOMMENDATION_BY_TYPE, recommendationType];

  return {
    invalidate: (currentQueryKey) => queryClient.invalidateQueries(currentQueryKey || queryKey),
    reset: (currentQueryKey) => queryClient.resetQueries(currentQueryKey || queryKey),
    fetchRecommendationsByTypeData: (currentLimit) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useQuery(
        queryKey,
        () =>
          fetchRecommendationDataByType(
            recommendationType,
            deprecatedGetCurrentDisplayedUserType,
            mapLinkedAccIdToDivisionName,
            currentLimit,
          ),
        {
          enabled: isEnabled,
          retry: false,
        },
      ),
     
    completeRecommendationByKeys: async function (recommendationKeys, recommendationType = null) {
      const recommendations = await fetchRecommendationDataByType(
        recommendationType,
        deprecatedGetCurrentDisplayedUserType,
        mapLinkedAccIdToDivisionName,
      );
      const recommendationsByKeys = recommendations.filter((rec) =>
        Array.isArray(recommendationKeys) ? recommendationKeys.includes(rec.key) : recommendationKeys === rec.key,
      );
      if (recommendationsByKeys.length) {
        await setRecommendationsStatus(recommendationsByKeys, RecommendationStatus.COMPLETED);
        await queryClient.invalidateQueries([apiConstants.QUERY_KEYS.RECOMMENDATION_BY_TYPE, recommendationType]);
        await queryClient.invalidateQueries([apiConstants.QUERY_KEYS.RECOMMENDATIONS_HISTORY]);
      }
    },
     
    excludeRecommendation: async function (recommendations, excludeData, excludeMessageMap) {
      if (recommendations) {
        await excludeRecommendationHelper(recommendations, excludeData, excludeMessageMap);
        await queryClient.invalidateQueries([apiConstants.QUERY_KEYS.RECOMMENDATION_BY_TYPE, recommendationType]);
        await queryClient.invalidateQueries([apiConstants.QUERY_KEYS.RECOMMENDATIONS_HISTORY]);
      }
    },
    addRecommendationsToMondayBoard: async (recommendations) => {
      if (recommendations) {
        await addRecommendationsToMondayBoard(recommendations);
        await queryClient.invalidateQueries([apiConstants.QUERY_KEYS.RECOMMENDATION_BY_TYPE, recommendationType]);
      }
    },
  };
};
