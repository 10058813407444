import React, { useCallback, useMemo } from 'react';
import { GenerateIcon, ICONS, SelectMulti } from '@pileus-cloud/anodot-frontend-common';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import Button from 'shared/components/andtComponents/Button';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import CustomersIcon from './CustomersIcon.jsx';
import { useCustomersContext } from './contexts/customersContext.jsx';
import styles from './CustomersHeader.module.scss';
import Switch from 'shared/components/andtComponents/SwitchButton/Switch';
import EditCustomerModal from 'users/containers/Organization/Customers/customerModals/EditCustomerModal.jsx';
import { palette } from 'shared/constants/colorsConstants.js';
import Input from 'shared/components/andtComponents/Input.jsx';
import { cloudTypeToIcon } from 'shared/constants/appConstants.js';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants.js';
import { fetchCustomersCSVData } from 'users/new-user-management/hooks/reactQuery/useMspCustomers.js';
import useDataAccessAccounts from 'users/new-user-management/hooks/reactQuery/useDataAccessAccounts.js';
import { debounce } from 'lodash';

const CustomersHeader = ({ total }) => {
  const [customerModalOpen, setCustomerModalOpen] = React.useState(false);
  const { filters, setFilters, isAllExpanded, setIsAllExpanded } = useCustomersContext();
  const { fetchDataAccessPayerAccounts } = useDataAccessAccounts()
  const { data: payerAccounts } = fetchDataAccessPayerAccounts();
  const [selectedLinkedAccounts, setSelectedLinkedAccounts] = React.useState(filters.linkedAccounts);
  const allLinkedAccounts = useMemo(() => {
    return payerAccounts?.reduce((acc, { linkedAccounts, payerAccount }) => {
      return acc.concat(linkedAccounts.map(c => ({ label: c.name, value: c.id, payerId: payerAccount.id })));
    }, []);
  }, [payerAccounts]);

  const updateFiltersLinkedAccounts = useCallback(debounce(values => {
    setFilters(filters => ({
      ...filters,
      linkedAccounts: values,
    }));
  }, 1000), []);
  const fetchExportData = async () => {
    const data = await fetchCustomersCSVData({});
    return [{ data, filename: 'customers.csv' }];
  };
  const handleCloudTypeFiltersUpdate = (cloudType) => {
    let cloudTypes = [...(filters.cloudTypes || [])];
    const currSelected = cloudTypes.includes(cloudType) || !cloudTypes.length;
    if (!cloudTypes.length) {
      cloudTypes = [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.AZURE, CLOUD_TYPE_IDS.GCP].filter((ct) => ct !== cloudType);
    } else {
      if (currSelected) {
        cloudTypes.splice(cloudTypes.indexOf(cloudType), 1);
      } else {
        cloudTypes.push(cloudType);
      }
    }
    setFilters({
      ...filters,
      cloudTypes,
    });
  };
  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerLeft}>
        <Button
          automationId="expand-rows"
          text=""
          isSecondary
          icon={() => <GenerateIcon iconName={isAllExpanded ? ICONS.collapse.name : ICONS.expand.name} />}
          onClick={() => {
            setIsAllExpanded(!isAllExpanded);
          }}
        />
        <div className={styles.header}>
          <CustomersIcon />
          <div className={styles.title}>
            Customers <span className={styles.count}>({total})</span>
          </div>
        </div>
        <div className={styles.filters}>
          <Switch
            options={[
              { label: 'Customers', value: 'customers', isSelected: filters.mode === 'customers', color: palette.purple[500] },
              { label: 'Accounts', value: 'accounts', isSelected: filters.mode === 'accounts', color: palette.purple[500] },
            ]}
            color={palette.purple[500]}
            onChange={(e, option) => {
              setFilters({
                ...filters,
                linkedAccounts: [],
                search: '',
                mode: option.value,
              })
              setIsAllExpanded(false);
            }}
          />
        </div>
      </div>
      <div className={styles.headerRight}>
        {filters.mode === 'customers' && (
          <div>
            <SelectMulti
              label="Linked Account"
              width={250}
              options={allLinkedAccounts}
              handleSelectionChange={(vals) => {
                const newVals = allLinkedAccounts.filter(({ value }) => vals?.some(v => v.value === value));
                setSelectedLinkedAccounts(newVals);
                updateFiltersLinkedAccounts(newVals);
              }}
              selected={selectedLinkedAccounts}
            />
          </div>
        )}
        {filters.mode === 'accounts' && (
          <>
            {[CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.AZURE, CLOUD_TYPE_IDS.GCP].map((cloudType) => (
              <Button
                overrideStyles={{ padding: 0 }}
                isTextButton
                text=''
                key={cloudType}
                className={!filters.cloudTypes?.length || filters.cloudTypes.includes(cloudType) ? {} : { button: styles.disabledCloud }}
                icon={cloudTypeToIcon[cloudType]}
                onClick={() => {
                  handleCloudTypeFiltersUpdate(cloudType);
                }}
              />
            ))}
          </>
        )}
        <div className="position-relative d-flex align-items-center">
          <Input
            searchComponent={<GenerateIcon iconName={ICONS.search.name} />}
            overrideStyles={{ height: 36 }}
            isSearch
            value={filters.search || ''}
            placeholder="Search"
            onChange={(e) => {
              setFilters({
                ...filters,
                search: e.target.value,
              });
            }}
          />
        </div>
        <Button
          text="Add Customer"
          onClick={() => {
            setCustomerModalOpen(true);
          }}
          disabled={false}
          icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
          automationId="newCustomer"
        />
        <Tooltip
          title="Export"
          arrow
        >
          <CustomCSVDownload
            fetchData={fetchExportData}
            automationId="export-customers"
            showDownloadIcon
            attachAccountTags={false}
            isLoading={false}
            filesNumber={1}
            isSecondary
            isLightButton
            hideText
            style={{ height: 36 }}
          />
          <span />
        </Tooltip>
      </div>
      {customerModalOpen && (
        <EditCustomerModal
          isOpen={customerModalOpen}
          setIsOpen={setCustomerModalOpen}
        />
      )}
    </div>
  );
};

export default CustomersHeader;
