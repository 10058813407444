import React from 'react';
import PropTypes from 'prop-types';
import ColoredTabs from 'shared/components/tabs/ColoredTabs';
import NotificationSettings from './components/NotificationSettings';
import Policies from './components/Policies';
import GeneralDetails from './components/GeneralDetails';
import { CLOUD_TYPE_IDS, UsersType } from '../../constants/usersConstants';

const Settings = ({ usersStore }) => {
  const { deprecatedGetCurrentDisplayedUserType: userType, currDispUserCloudAccountType: cloudAccountType } =
    usersStore;
  const checkIfPoliciesTabDisplayed = () =>
    cloudAccountType === CLOUD_TYPE_IDS.AWS &&
    (userType === UsersType.RESELLER || userType === UsersType.USER || userType === UsersType.SUPER_USER);
  const arrOfNavItems = [
    { id: 0, name: 'Notifications Settings' },
    { id: 1, name: 'Policies', disabled: !checkIfPoliciesTabDisplayed() },
    {
      id: 2,
      name: 'General Info',
      disabled: userType === UsersType.RESELLER_CUSTOMER_EDP || userType === UsersType.RESELLER_CUSTOMER,
    },
  ];
  return (
    <ColoredTabs arrOfNavItems={arrOfNavItems.filter((nav) => !nav.disabled)} vertical>
      <NotificationSettings usersStore={usersStore} />
      <Policies usersStore={usersStore} />
      <GeneralDetails usersStore={usersStore} />
    </ColoredTabs>
  );
};

Settings.propTypes = {
  usersStore: PropTypes.object.isRequired,
};

export default Settings;
