import React, { useState } from 'react';
import {
  GenerateIcon,
  ICONS,
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import { CUSTOMER_ACTIONS } from '../consts';
import EditCustomerModal from './customerModals/EditCustomerModal';
import DeleteCustomersModal from './customerModals/DeleteCustomerModal';

import styles from './CustomerActions.module.scss';
import { Action, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper.jsx';
import { Routes } from 'shared/constants/routes.js';
import Tooltip from 'shared/components/andtComponents/Tooltip.jsx';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import { getLinkedAccountName } from 'shared/utils/cloudUtils.js';

const CustomersActions = ({ row }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { usersStore } = useRootStore();
  const [isEditCustomerModalOpen, setIsEditCustomerModalOpen] = useState(false);
  const [isDeleteCustomerModalOpen, setIsDeleteCustomerModalOpen] = useState(false);

  const toggleOpen = (e) => {
    e.stopPropagation();
  };

  const onCustomerActionSelect = (action) => {
    switch (action) {
      case CUSTOMER_ACTIONS.EDIT.id:
        setIsEditCustomerModalOpen(true);
        break;
      case CUSTOMER_ACTIONS.DELETE.id:
        setIsDeleteCustomerModalOpen(true);
        break;
      default:
        break;
    }
    setIsOpen(false);
  };

  return (
    <div className={styles.customerActionsContainer} onClick={(e) => toggleOpen(e)}>
      <ReadOnlyDisplayWrapper
        isHide={false}
        category={OrganizationEntityCategory.ResellerCustomers}
        action={Action.Update}
      >
        <Tooltip title={`Add/Edit ${getLinkedAccountName(usersStore.currDispUserCloudAccountType)}s`}>
          <span className={styles.secondaryButton}>
            <Button
              text=""
              isSecondaryWithHover
              automationId={`addLinkedAccountsButton-${row.name}`}
              icon={<GenerateIcon iconName={ICONS.addLinkedAccounts.name} />}
              onClick={(e) => {
                e.stopPropagation();
                setIsEditCustomerModalOpen(true);
              }}
            />
          </span>
        </Tooltip>
      </ReadOnlyDisplayWrapper>
      <Tooltip title="Open Customer scope">
        <span>
          <Button
            isTextButton
            automationId="diveIntoCustomer"
            text=""
            onClick={() => {
              usersStore.deprecatedChangeCustomerUserType();
              usersStore.rootStore.appStore.updatePricingMode(true);
              usersStore.updateCurrDisplayedDivIdAndName(row.id, row.name);
              usersStore.rootStore.invalidateStores();
              history.push(Routes.COST_USAGE_EXPLORER);
            }}
            icon={<GenerateIcon iconName={ICONS.magnifyingGlassDollar.name} />}
          />
        </span>
      </Tooltip>
      <ReadOnlyDisplayWrapper category={OrganizationEntityCategory.ResellerCustomers} action={Action.Update}>
        <Popover open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
          <PopoverTrigger as="button" type="button">
            <GenerateIcon iconName={ICONS.verticalDots.name} className={styles.actionsIcon} />
          </PopoverTrigger>

          <PopoverPortal>
            <PopoverContent sideOffset={10} side="bottom" className={styles.popoverContent}>
              <Button
                isTextButton
                text={CUSTOMER_ACTIONS.EDIT.label}
                automationId={CUSTOMER_ACTIONS.EDIT.automationId}
                className={{ button: styles.menuItem }}
                icon={() => <GenerateIcon iconName={CUSTOMER_ACTIONS.EDIT.icon} className={styles.itemIcon} />}
                onClick={() => onCustomerActionSelect(CUSTOMER_ACTIONS.EDIT.id)}
              />
              <Button
                isTextButton
                text={CUSTOMER_ACTIONS.DELETE.label}
                automationId={CUSTOMER_ACTIONS.DELETE.automationId}
                className={{ button: styles.menuItem }}
                icon={() => <GenerateIcon iconName={CUSTOMER_ACTIONS.DELETE.icon} className={styles.itemIcon} />}
                onClick={() => onCustomerActionSelect(CUSTOMER_ACTIONS.DELETE.id)}
              />
            </PopoverContent>
          </PopoverPortal>
        </Popover>
      </ReadOnlyDisplayWrapper>
      {isEditCustomerModalOpen && (
        <EditCustomerModal setIsOpen={setIsEditCustomerModalOpen} isOpen={isEditCustomerModalOpen} customer={row} />
      )}
      {isDeleteCustomerModalOpen && (
        <DeleteCustomersModal
          setIsOpen={setIsDeleteCustomerModalOpen}
          isOpen={isDeleteCustomerModalOpen}
          customer={row}
        />
      )}
    </div>
  );
};

export default CustomersActions;
