import React, { useState } from 'react';
import toast from 'shared/components/andtComponents/Toast';
import useUsers from 'users/new-user-management/hooks/reactQuery/useUsers';
import { USER_STATUS_OPTIONS } from '../consts';
import Switch from 'shared/components/andtComponents/SwitchButton/Switch.jsx';
import { palette } from 'shared/constants/colorsConstants.js';

const getUserStatusOptions = (enabled) => [
  { label: USER_STATUS_OPTIONS.ENABLED, isSelected: enabled === true, color: palette.eucaliptus[500] },
  { label: USER_STATUS_OPTIONS.DISABLED, isSelected: enabled === false, color: palette.gray[350] },
];

const UserStatusSwitchButton = ({ row, isViewOnly }) => {
  const [isEnableDisableInProgress, setIsEnableDisableInProgress] = useState(false);
  const { enableDisableUsers } = useUsers();

  const optionsList = Object.values(getUserStatusOptions(row?.enabled));

  const changeUserState = async (e, option) => {
    if (isViewOnly) {
      return;
    }
    e.stopPropagation();
    try {
      setIsEnableDisableInProgress(true);
      await enableDisableUsers.mutateAsync({
        usersIds: [row?.id],
        enable: option.label === USER_STATUS_OPTIONS.ENABLED,
      });
    } catch (error) {
      toast.error('Error while changing user status');
      setIsEnableDisableInProgress(false);
    }
  };

  return (
    <Switch
      isDisabled={isViewOnly}
      color={palette.eucaliptus[500]}
      isLoading={isEnableDisableInProgress}
      onChange={changeUserState}
      options={optionsList}
    />
  );
};

export default UserStatusSwitchButton;
