import React, { useEffect, useState } from 'react';
import Spinner from 'shared/components/andtComponents/Spinner';
import useTable from 'shared/hooks/customHooks/useTable';
import useUsers from 'users/new-user-management/hooks/reactQuery/useUsers';
import UsersHeader from './UsersHeader';
import UsersTable from './UsersTable';
import { UsersContext } from './contexts/usersContext';
import { GET_USERS_QUERY_PARAMS, PAGINATION_DIRECTION } from '../consts';

import styles from './Users.module.scss';

const Users = () => {
  const { NewTableWrapper } = useTable();
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [paginationToken, setPaginationToken] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [tableFilters, setTableFilters] = useState({});

  const usersHook = useUsers();
  const { data, isLoading } = usersHook.fetchUsers({ ...tableFilters });
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchParam = searchParams.get(GET_USERS_QUERY_PARAMS.SEARCH.name);
    if (searchParam) {
      setTableFilters({ ...tableFilters, [GET_USERS_QUERY_PARAMS.SEARCH.name]: searchParam });
    }
  }, []);

  const pageChanged = (direction) => {
    if (direction === PAGINATION_DIRECTION.NEXT && data?.paginationToken) {
      setTableFilters({
        ...tableFilters,
        [GET_USERS_QUERY_PARAMS.PAGINATION_TOKEN.name]: data?.paginationToken,
      });
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <div className={styles.users}>
      <UsersContext.Provider
        value={{
          isAllExpanded,
          paginationToken,
          setIsAllExpanded,
          showFilters,
          setShowFilters,
          selectedRows,
          setSelectedRows,
          setPaginationToken,
          setTableFilters,
          tableFilters,
        }}
      >
        <NewTableWrapper>
          <UsersHeader pageChanged={pageChanged} total={data?.total} />
          <UsersTable users={data?.users || []} />
        </NewTableWrapper>
      </UsersContext.Provider>
    </div>
  );
};

export default Users;
