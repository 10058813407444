import { Grid, TableColumnResizing, TableHeaderRow, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import {
  CUSTOMERS_ACCOUNTS_COLUMNS,
  getColumns,
  getDefaultSorting,
  getSortingColumns,
} from 'users/containers/Organization/consts.js';
import { DataTypeProvider, RowDetailState, SortingState } from '@devexpress/dx-react-grid';
import { cloudTypeToIcon } from 'shared/constants/appConstants.js';
import Switch from 'shared/components/andtComponents/SwitchButton/Switch.jsx';
import PlainSwitch from 'shared/components/andtComponents/Switch.jsx';
import { palette } from 'shared/constants/colorsConstants.js';
import { GenerateIcon, ICONS, Popover, PopoverContent, PopoverTrigger } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button.jsx';
import Input from 'shared/components/andtComponents/Input.jsx';
import {
  CustomFormatterWithTooltip,
  CustomFormatterWithTooltipAndComponent,
  CustomHeaderCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents.jsx';
import TableWrapper from 'shared/components/tables/TableWrapper.jsx';
import React, { useEffect, useMemo, useState } from 'react';
import useTable from 'shared/hooks/customHooks/useTable.jsx';
import styles from './CustomersAccountsTable.module.scss';
import { useCustomersContext } from 'users/containers/Organization/Customers/contexts/customersContext.jsx';
import AccountCustomerExpanded
  from 'users/containers/Organization/Customers/customersExpandedRowComponents/AccountCustomerExpanded/AccountCustomerExpanded.jsx';
import { useAccounts } from 'users/new-user-management/hooks/reactQuery/useAccounts.js';

const ExcludeRuleCell = ({ row }) => {
  const [excludeSettingOpen, setExcludeSettingOpen] = useState(null);
  const [excludeValue, setExcludeValue] = useState(row.autoAssignExclMatch);
  const { updateAccountProperties } = useAccounts();
  const handleUpdate = updateAccountProperties();
  if (!row.isAutoAssignLinkedAccounts) {
    return null;
  }
  return (
    <div
      className={styles.excludeRuleModal}
      onClick={e => e.stopPropagation()}
    >
      <Popover
        open={excludeSettingOpen === row.id}
        onOpenChange={() => {
          setExcludeSettingOpen(excludeSettingOpen ? null : row.id);
          setExcludeValue(excludeSettingOpen ? '' : row.autoAssignExclMatch);
        }}
      >
        <PopoverTrigger as="button">
          <span>
            {row.autoAssignExclMatch ? (
              <span className={styles.excludeValue}>
                {row.autoAssignExclMatch}
                <GenerateIcon iconName={ICONS.edit.name} />
              </span>
            ) : (
              <Button
                onClick={() => {
                }}
                isLoading={handleUpdate.isLoading && handleUpdate.variables?.id === row.id}
                overrideStyles={{ paddingLeft: 0 }}
                isTextButton
                text="Add Exclusion Rule"
              />
            )}
          </span>
        </PopoverTrigger>
        <div className={styles.excludeRuleModal}>
          <PopoverContent
            className={styles.excludeRuleModalContent}
            side="bottom"
            sideOffset={1}
          >
            <div>
              <h4>Exclude Linked Accounts Rule</h4>
              <p>
                Define the pattern for linked accounts that are excluded from the auto-assignment process of accounts to customers.
                You can use ‘*’ as a wildcard in this pattern. Example Text*SP*@MSP.com
              </p>
              <Input
                value={excludeValue}
                onChange={(e) => {
                  setExcludeValue(e.target.value);
                }}
              />
              <div className={styles.popoverFooter}>
                <Button
                  text="Cancel"
                  isSecondary
                  onClick={() => setExcludeSettingOpen(null)}
                />
                <Button
                  text="Save"
                  onClick={async () => {
                    await handleUpdate.mutateAsync({
                      id: row.id,
                      excludedLinkedAccountMatch: excludeValue,
                    })
                    setExcludeSettingOpen(null);
                  }}
                />
              </div>
            </div>
          </PopoverContent>
        </div>
      </Popover>
    </div>
  );
}

const AutoCustomerCell = ({ row }) => {
  const [autoCustomerOpen, setAutoCustomerOpen] = useState(null);
  const [autoCustomerValue, setAutoCustomerValue] = useState(false);
  const { updateAccountProperties } = useAccounts();
  const handleUpdate = updateAccountProperties();
  if (!row.azureAutoCreatePropName) {
    return null;
  }
  return (
    <div
      onClick={e => e.stopPropagation()}
    >
      <Popover
        open={autoCustomerOpen === row.id}
        onOpenChange={() => {
          setAutoCustomerOpen(autoCustomerOpen ? null : row.id);
          setAutoCustomerValue(!autoCustomerOpen);
        }}
      >
        <PopoverTrigger as="button">
          <Button
            onClick={() => {
            }}
            overrideStyles={{ paddingLeft: 0 }}
            isTextButton
            text="Remove Auto Customer"
            icon={() => <GenerateIcon iconName={ICONS.circleXmark.name} />}
          />
        </PopoverTrigger>
        <div className={styles.autoCustomerModal}>
          <PopoverContent
            className={styles.autoCustomerModalContent}
            side="bottom"
            sideOffset={1}
          >
            <div>
              <div className={styles.switchLine} onClick={e => e.stopPropagation()}>
                Create and Manage Customers manually
                <PlainSwitch
                  isChecked={autoCustomerValue}
                  onChange={() => {
                    setAutoCustomerValue(!autoCustomerValue)
                  }}
                />
              </div>
              <p>
                Currently the process is automatic and based on Azure logic. Once it is changed to a manual process, it cannot be changed back to an automatic process.
              </p>
            </div>
            <div className={styles.popoverFooter}>
              <Button
                text="Cancel"
                isSecondary
                onClick={() => setAutoCustomerOpen(null)}
              />
              <Button
                text="Save"
                onClick={async () => {
                  if (!autoCustomerValue) {
                    await handleUpdate.mutateAsync({
                      id: row.id,
                      autoCreateCustomersPropertyName: null,
                    })
                  }
                  setAutoCustomerOpen(null);
                }}
              />
            </div>
          </PopoverContent>
        </div>
      </Popover>
    </div>
  );
}

const filterByCloudType = (accounts, cloudTypes) => {
  if (!cloudTypes?.length) {
    return accounts;
  }
  return accounts.filter((account) => cloudTypes.includes(+account.cloudType));
}

const filterBySearch = (accounts, search) => {
  if (!search) {
    return accounts;
  }
  return accounts.filter((account) => {
    const searchLower = search.toLowerCase();
    return account.name.toLowerCase().includes(searchLower) || account.id.toLowerCase().includes(searchLower);
  });
}

const CustomersAccountsTable = ({ accounts }) => {
  const [localColumnsAccountsWidth, setLocalColumnsAccountsWidth] = useState(
    Object.values(CUSTOMERS_ACCOUNTS_COLUMNS).map((c) => ({ columnName: c.columnName, width: c.width })),
  );
  const { NewTableRow, CustomToggleCell } = useTable();
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const { filters, isAllExpanded } = useCustomersContext();
  const { updateAccountProperties } = useAccounts();
  const handleUpdate = updateAccountProperties();
  const accountsFilters = useMemo(() => {
    return filterByCloudType(filterBySearch(accounts, filters.search), filters.cloudTypes);
  }, [accounts, filters.search, filters.cloudTypes]);

  useEffect(() => {
    if (!isAllExpanded) {
      setExpandedRowIds([]);
    } else {
      setExpandedRowIds(accountsFilters?.map((row, index) => index));
    }
  }, [isAllExpanded]);

  return (
    <Grid
      id="accounts-customers-list"
      rows={accountsFilters}
      columns={getColumns(CUSTOMERS_ACCOUNTS_COLUMNS)}
      automationId="accounts-customers-list"
    >
      <SortingState
        columnExtensions={getSortingColumns(CUSTOMERS_ACCOUNTS_COLUMNS)}
        defaultSorting={getDefaultSorting(CUSTOMERS_ACCOUNTS_COLUMNS)}
      />
      <RowDetailState
        expandedRowIds={expandedRowIds}
        onExpandedRowIdsChange={setExpandedRowIds}
      />
      <DataTypeProvider
        for={[CUSTOMERS_ACCOUNTS_COLUMNS.CUSTOMERS_COUNT.columnName]}
        formatterComponent={(props) => props.row.customers?.length || 0}
      />
      <DataTypeProvider
        for={[CUSTOMERS_ACCOUNTS_COLUMNS.ACCOUNT_NAME.columnName]}
        formatterComponent={(props) => {
          const CloudTypeIcon = cloudTypeToIcon[+props.row.cloudType];
          return (
            <CustomFormatterWithTooltipAndComponent isLeftComponent {...props}>
              {CloudTypeIcon ? <CloudTypeIcon /> : null}
            </CustomFormatterWithTooltipAndComponent>
          );
        }}
      />
      <DataTypeProvider
        for={[CUSTOMERS_ACCOUNTS_COLUMNS.AUTO_ASSIGNED.columnName]}
        formatterComponent={(props) => {
          return (
            <div
              className={styles.switchContainer}
              onClick={e => e.stopPropagation()}
            >
              <Switch
                isLoading={handleUpdate.isLoading && handleUpdate.variables?.id === props.row.id}
                className={styles.onOffSwitch}
                options={[
                  { label: 'On', value: 'on', isSelected: props.row.isAutoAssignLinkedAccounts, color: palette.purple[500] },
                  { label: 'Off', value: 'off', isSelected: !props.row.isAutoAssignLinkedAccounts, color: palette.purple[500] },
                ]}
                color={palette.purple[500]}
                onChange={async (e, option) => {
                  await handleUpdate.mutateAsync({
                    id: props.row.id,
                    autoAssignLinkedAccounts: option.value === 'on',
                  });
                }}
              />
            </div>
          );
        }}
      />
      <DataTypeProvider
        for={[CUSTOMERS_ACCOUNTS_COLUMNS.EXCLUDE_RULE.columnName]}
        formatterComponent={ExcludeRuleCell}
      />
      <DataTypeProvider
        for={[CUSTOMERS_ACCOUNTS_COLUMNS.AUTO_CUSTOMER.columnName]}
        formatterComponent={AutoCustomerCell}
      />
      <DataTypeProvider
        for={[CUSTOMERS_ACCOUNTS_COLUMNS.ACCOUNT_ID.columnName]}
        formatterComponent={(props) => {
          return (
            <CustomFormatterWithTooltip value={props.row.id} />
          );
        }}
      />
      <DataTypeProvider
        for={[CUSTOMERS_ACCOUNTS_COLUMNS.ACCOUNT_TYPE.columnName]}
        formatterComponent={(props) => props.row.isFullyDedicated ? 'Dedicated' : 'Shared'}
      />
      <TableWrapper
        virtual
        rowComponent={(props) => (
          <NewTableRow
            expandedRowIds={expandedRowIds}
            setExpandedRowIds={setExpandedRowIds} {...props} />
        )}
        height="auto"
      />
      <TableColumnResizing
        resizingMode="nextColumn"
        columnWidths={localColumnsAccountsWidth}
        onColumnWidthsChange={setLocalColumnsAccountsWidth}
      />
      <TableRowDetail
        contentComponent={({ row }) => <AccountCustomerExpanded customers={row.customers} accountsData={accounts} accountId={row.id} />}
        toggleCellComponent={CustomToggleCell}
      />
      <TableHeaderRow cellComponent={CustomHeaderCell} />
    </Grid>
  );
};

export default CustomersAccountsTable;
