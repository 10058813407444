import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import useTokens from 'users/new-user-management/hooks/reactQuery/useTokens';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { Routes } from 'shared/constants/routes';
import Button from 'shared/components/andtComponents/Button';
import { useRootStore } from 'app/contexts/RootStoreContext';
import toast from 'shared/components/andtComponents/Toast';
import styles from './UserImpersonate.module.scss';

const UserImpersonate = ({ row: { id, firstName, lastName, username, impersonatable = false }, history }) => {
  const { useExchangeTokenByUserImpersonationToken } = useTokens();
  const { usersStore } = useRootStore();
  const { mutate: exchangeTokenByUserImpersonationToken, isLoading } = useExchangeTokenByUserImpersonationToken({
    handleOnSuccess: onImpersonateSuccess,
  });

  async function onImpersonateSuccess({ accessToken }) {
    if (!accessToken) {
      toast.error('Failed to impersonate user');
      return;
    }
    await usersStore.impersonateUser({ token: accessToken, userId: id });
    history.push(Routes.LANDING);
  }

  const impersonateUser = async (e) => {
    e.stopPropagation();
    if (isLoading) {
      return;
    }
    exchangeTokenByUserImpersonationToken(id);
  };
  const getImpersonateTooltipText = () => {
    if (firstName && lastName) {
      return `Impersonate ${firstName} ${lastName}`;
    }
    return `Impersonate ${username}`;
  };
  if (usersStore?.authUserKey !== id && !!impersonatable) {
    return (
      <Tooltip title={getImpersonateTooltipText()} placement="top">
        <Button
          onClick={impersonateUser}
          isTextButton
          isLoading={isLoading}
          overrideStyles={{ padding: 0 }}
          icon={<GenerateIcon iconName={ICONS.userSecret.name} className={styles.impersonateIcon} />}
        />
      </Tooltip>
    );
  }
  return null;
};

UserImpersonate.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    impersonatable: PropTypes.bool.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const UserImpersonateWithRouter = withRouter(UserImpersonate);
export default UserImpersonateWithRouter;
