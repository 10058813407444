import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { adaptV4Theme, createTheme } from '@mui/material/styles';
import { BrowserRouter, withRouter } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'bootstrap/dist/css/bootstrap.css';
import RootStore from 'app/store/rootStore';
import * as fsHandler from 'shared/modules/fullStoryHandler';
import { ModelNames } from 'shared/constants/appConstants';
import ApiGateway from 'shared/modules/apiGateway';
import InvoicesApi from 'invoices/api/invoicesApi';
import UsageApi from 'usage/api/usageApi';
import CommitmentApi from 'commitment/api/commitmentApi';
import DivisionsApi from 'divisions/api/divisionsApi';
import KubernetesApi from 'kubernetes/api/kubernetesApi';
import CustomDashboardApi from 'usage/containers/CustomDashboard/api/customDashboardApi';
import { withRootStoreContextProvider } from 'app/contexts/RootStoreContext';
import { withInvoiceFiltersContextProvider } from 'invoices/contexts/InvoiceFiltersContext';
import { signOut, UsersApi } from 'users/api/usersApi';
import { createCustomHeaders } from 'shared/utils/apiUtil';
import { checkIfTokenRefreshNeeded, setLocalStorage, parseJwt, getValueFromStorage } from 'shared/utils/tokenUtil';
import Spinner from 'shared/components/andtComponents/Spinner';
// models
import UsersModel from 'users/models/usersModel';
import CostAndUsageAlertsModel from 'usage/models/costAndUsageAlertsModel';
import CustomDashboardModel from 'usage/store/subStores/customDashBoard/customDashboardModel';
import CommitmentModel from 'commitment/models/commitmentModel';
import KubernetesModel from 'kubernetes/models/kubernetesModel';
import { UserSettingsProvider } from 'users/utils/contexts/UserSettingsContext';
import config from 'config';

// Layout & Scss
import 'scss/app.js';
import ScrollToTop from './ScrollToTop';
// Routing
import Router from './Router';
import CustomThemeApplier from './components/CustomThemeApplier';
import { logoutOkta, logoutPing } from './logoutIdp';
import {
  createKeycloakInstance,
  getKeycloakRealm,
  getKeycloakToken,
  getKyeycloakRefreshToken,
  getRealmLocally,
  initKeycloak,
  isKeycloakAuthenticated,
  keycloakLogout,
  setKyeycloakRefreshToken,
  persistRealm,
  resetKeycloakInstance,
} from 'shared/keycloak/keycloak.service';

fsHandler.init();

const muiTheme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: '#2671FF',
      },
      secondary: {
        main: 'rgba(0, 0, 0, 0.87)',
      },
    },
    overrides: {
      MuiTableCell: {
        root: {
          padding: 4,
        },
      },
      MuiTableSortLabel: {
        root: {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
      Table: {
        stickyTable: {
          zIndex: 0,
        },
      },
      MuiTableRow: {
        root: {
          height: 48,
        },
      },
    },
  }),
);

const createApiGateway = () => {
  const invoicesApi = new InvoicesApi();
  const usersAPI = new UsersApi();
  const usageApi = new UsageApi();
  const divisionsApi = new DivisionsApi();
  const kubernetesApi = new KubernetesApi();
  const commitmentApi = new CommitmentApi();
  const customDashboardApi = new CustomDashboardApi();
  const apiGateway = new ApiGateway(
    invoicesApi,
    usersAPI,
    usageApi,
    divisionsApi,
    kubernetesApi,
    commitmentApi,
    customDashboardApi,
  );
  return apiGateway;
};

const createModels = (apiGateway) => {
  const usersModel = new UsersModel(apiGateway);
  const costAndUsageAlertsModel = new CostAndUsageAlertsModel(apiGateway);
  const kubernetesModel = new KubernetesModel(apiGateway);
  const commitmentModel = new CommitmentModel(apiGateway);
  const customDashboardModel = new CustomDashboardModel(apiGateway);
  const modelsMap = new Map();
  modelsMap.set(ModelNames.USERS_MODEL, usersModel);
  modelsMap.set(ModelNames.CUE_ALERTS_MODEL, costAndUsageAlertsModel);
  modelsMap.set(ModelNames.K8S_USAGE_MODEL, kubernetesModel);
  modelsMap.set(ModelNames.COMMITMENT_MODEL, commitmentModel);
  modelsMap.set(ModelNames.CUSTOM_DASHBOARD_MODEL, customDashboardModel);
  return modelsMap;
};

const createRootStore = () => {
  const apiGateway = createApiGateway();
  const map = createModels(apiGateway);
  const rootStore = new RootStore(map);
  return rootStore;
};

const rootStore = createRootStore();

config.apiReqHeaders.setCreateHeadersFunc(createCustomHeaders(rootStore));

const handleVisibilityChange = () => {
  if (document.visibilityState === 'visible') {
    // do not await here
    checkIfTokenRefreshNeeded();
  }
};

class App extends Component {
  constructor() {
    super();
    this.state = {
      newTabLoading: true,
      isAuthenticated: false,
      username: '',
    };
  }

  async componentDidMount() {
    this.handleNewTab();
    const { queryClient } = this.props;
    rootStore.queryClient = queryClient;
    document.addEventListener('visibilitychange', handleVisibilityChange);
    // bind handleLogout to window in order to be able to call it from apiMiddleware
    window.handleLogout = this.handleLogout.bind(this);
  }
  componentWillUnmount() {
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  }
  setUsername = (username) => {
    this.setState({ username });
  };

  getDispUserKeyFromStorage = () =>
    window.sessionStorage.getItem('dispUserKey') || window.localStorage.getItem('dispUserKey');

  updateAccountScopeFromUrl = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const accountId = queryParams.get('accountId');
    let accountKey = queryParams.get('accountKey') ?? localStorage.getItem('currDispUserAccountKey');
    const divisionId = queryParams.get('divisionId') ?? localStorage.getItem('currDispUserDivisionId');
    const isPpApplied = queryParams.get('isPpApplied') ?? localStorage.getItem('isPpApplied');
    if (accountId != null) {
      accountKey = rootStore.usersStore.getAccountByAccountId(accountId)?.accountKey ?? accountKey;
    }
    if (accountKey != null) {
      rootStore.usersStore.handleDisplayedAccountChange(accountKey);
    }
    if (divisionId != null) {
      rootStore.usersStore.updateCurrDisplayedDivisionId(divisionId);
      if (+divisionId > 0) {
        // Legacy UM
        rootStore.usersStore.deprecatedChangeCustomerUserType();
      }
    }
    if (isPpApplied != null) {
      rootStore.appStore.updatePricingMode(!!JSON.parse(isPpApplied));
    }
  };

  keycloakAuthentication = async (realm) => {
    createKeycloakInstance(realm);
    const refreshToken = window.sessionStorage.getItem('refreshToken') || window.localStorage.getItem('refreshToken');
    if (refreshToken) {
      setKyeycloakRefreshToken(refreshToken);
    }
    await initKeycloak();
    const keycloakAuthenticated = isKeycloakAuthenticated();
    if (keycloakAuthenticated) {
      const keycloakAuthToken = getKeycloakToken();
      const keycloakRefreshToken = getKyeycloakRefreshToken();
      const keycloakTokenPayload = parseJwt(keycloakAuthToken);
      const useSessionStorage = keycloakTokenPayload['custom:useSessionStorage'] === '1';
      setLocalStorage('authToken', keycloakAuthToken, useSessionStorage);
      setLocalStorage('refreshToken', keycloakRefreshToken, useSessionStorage);
      const { usersStore } = rootStore;
      const userData = await usersStore.signinWithToken();
      const { userKey, userName } = userData;
      const authUserKey = getValueFromStorage('impersonationToken') ? keycloakTokenPayload.sub : userKey;
      usersStore.updateCurrentAuthUser(authUserKey);
      usersStore.updateCurrentDisplayedUserKey(userKey);
      usersStore.updateCurrentDisplayedUserName(userName);
    } else {
      persistRealm('');
      resetKeycloakInstance();
    }
  };

  handleNewTab = async () => {
    if (rootStore) {
      const realm = getRealmLocally();
      if (realm) {
        await this.keycloakAuthentication(realm);
      }
      if (this.isSessionStorageAuth()) {
        const dispUserKey = this.getDispUserKeyFromStorage();
        const authUserKey = window.sessionStorage.getItem('authUserKey') || window.localStorage.getItem('authUserKey');
        if (authUserKey) {
          rootStore.usersStore.updateCurrentAuthUser(authUserKey);
          rootStore.usersStore.updateCurrentDisplayedUserKey(authUserKey);
          await rootStore.usersStore.initMainUser();
          this.userHasAuthenticated();
          if (authUserKey !== dispUserKey) {
            await rootStore.usersStore.handleDisplayedUserChange(dispUserKey);
          }
          this.updateAccountScopeFromUrl();
          rootStore.fetchData(dispUserKey);
        }
      }
    }
    this.setState(() => ({ newTabLoading: false }));
  };

  userHasAuthenticated = () => {
    this.setState({ isAuthenticated: true });
    const userKey = rootStore.usersStore.currentDisplayedUserKey;
    const email = rootStore.usersStore.currentDisplayedUserName;
    const displayName = email;
    try {
      fsHandler.identify(userKey, displayName, email);
    } catch {
      // FullStory might not be available
    }
  };

  isSessionStorageAuth = () => {
    const authToken = window.sessionStorage.getItem('authToken') || window.localStorage.getItem('authToken');
    const refreshToken = window.sessionStorage.getItem('refreshToken') || window.localStorage.getItem('refreshToken');
    const authTokenData = authToken?.split('.')[1];
    if (!authToken || !authTokenData) {
      return false;
    }
    const validBase64TokenData = authTokenData.replace(/-/g, '+').replace(/_/g, '/');
    const userData = JSON.parse(window.atob(validBase64TokenData));
    const exp = userData.exp * 1000; // seconds to milliseconds
    const now = Date.now();
    const isLive = exp > now;
    return !!isLive || !!refreshToken;
  };

  clearOnLogout = () => {
    window.sessionStorage.clear();
    window.localStorage.clear();
    persistRealm(''); // remove realm from cookies
  };

  async handleLogout(redirect = '') {
    try {
      const realm = getKeycloakRealm();

      // Handle Keycloak logout
      if (realm) {
        this.clearOnLogout();
        keycloakLogout();
        return;
      }

      // Handle Cognito logout
      const token = this.getAuthToken();
      const ssoLogoutConfig = await this.performSSOLogout();

      this.setState({ isAuthenticated: false });
      this.clearOnLogout();
      this.setUsername('');
      rootStore.invalidateStoresLogOut();

      if (ssoLogoutConfig?.isLogoutIdP) {
        await this.handleIdPLogout(ssoLogoutConfig, token);
      }

      this.redirectAfterLogout(redirect);
    } catch {
      // Token might be expired, handle silently
    }
  }

  getAuthToken() {
    return window.sessionStorage.getItem('authToken') || window.localStorage.getItem('authToken');
  }

  async performSSOLogout() {
    try {
      return await signOut();
    } catch {
      // Return undefined if sign out fails, nothing to do
      return undefined;
    }
  }

  async handleIdPLogout(ssoLogoutConfig, token) {
    const tokenPayload = parseJwt(token);
    const provider = tokenPayload?.identities?.[0]?.providerName;
    const idpSettings = ssoLogoutConfig?.idpIdentifiers?.[provider];

    if (!idpSettings) return;

    const { idp, clientId } = idpSettings;
    const idToken = tokenPayload['custom:id_token'];

    if (idToken) {
      const { iss: issuer } = parseJwt(idToken);
      switch (idp) {
        case 'okta':
          logoutOkta({ idToken, issuer });
          break;
        case 'ping':
          logoutPing({ clientId });
          break;
        default:
          break;
      }
    }
  }

  redirectAfterLogout(redirect) {
    if (redirect) {
      window.location.href = redirect;
    } else {
      window.location.reload();
    }
  }

  render() {
    const { newTabLoading, isAuthenticated, username } = this.state;
    const { usersStore, invoiceStore, usageStore, appStore, kubernetesStore, commitmentStore } = rootStore;
    const childProps = {
      isAuthenticated,
      newTabLoading,
      userHasAuthenticated: this.userHasAuthenticated,
      isSessionStorageAuth: this.isSessionStorageAuth,
      handleLogout: this.handleLogout.bind(this),
      username,
      setUsername: this.setUsername.bind(this),
      rootStore,
      usersStore,
      invoiceStore,
      usageStore,
      appStore,
      kubernetesStore,
      commitmentStore,
    };
    const customTheme = usersStore.getCurrDisplayedUserTheme();
    if (newTabLoading) {
      return <Spinner />;
    }
    return (
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={muiTheme}>
            <UserSettingsProvider>
              <ScrollToTop>
                <div automation-id="app">
                  <ToastContainer hideProgressBar position="bottom-left" />
                  <CustomThemeApplier customThemeName={customTheme} />
                  <Router childProps={childProps} />
                  <ReactQueryDevtools initialIsOpen={false} />
                </div>
              </ScrollToTop>
            </UserSettingsProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    );
  }
}

App.propTypes = {
  queryClient: PropTypes.object.isRequired,
};
const appComponentWithContext = withInvoiceFiltersContextProvider(
  withRootStoreContextProvider(withRouter(App), rootStore),
  rootStore,
);
export default appComponentWithContext;
