import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomModal from 'shared/components/andtComponents/Modal.jsx';
import Button from 'shared/components/andtComponents/Button.jsx';
import ErrorMessage from 'shared/components/andtComponents/ErrorMessage.jsx';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext.jsx';
import RecipientsSelect from 'shared/components/RecipientsSelect.jsx';
import useChannels from 'shared/components/slackIntegration/hooks/useChannels.js';
import { initiateAlertRule, prepareAlertRule, validateAlertRule } from '../anomalyDetectionHelpers.js';
import styles from './addAlertRuleModal.module.scss';
import classNames from 'classnames';
import ColoredBlock from 'shared/components/ColoredFormBlock/index.tsx';
import { Form, Input } from 'reactstrap';
import AlertRuleFilters from './AddAlertRuleFilters.jsx';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import Tooltip from 'shared/components/andtComponents/Tooltip.jsx';
import { ReactComponent as InfoComponent } from 'shared/img/icons/info.svg';

const pricingModeTooltip =
  'The value is taken from pricing mode viewed at the alert creation (Partner/Customer). ' +
  'In case its set to customer, the anomaly and the alert will be considered the customer re-billed cost.';
const minCostImpactTooltip =
  'Represents the difference between the anomalous data point and the previous normal value.';
const minCostDeltaTooltip = 'Represents the difference between the anomalous data point and the previous normal value.';
const minDailyCostDeltaTooltip = 'Represents the % difference between the anomalous data point and the expected value for that day.'
const minDailyCostImpactTooltip = 'Represents the $ difference between the anomalous data point and the expected value for that day.'

const propTypes = {
  selectedAlertRule: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  alertRules: PropTypes.array,
  handleSaveAlertRule: PropTypes.func.isRequired,
  getMyEmail: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  filtersOptions: PropTypes.object,
};

const AddAlertRuleModal = ({
  selectedAlertRule = null,
  open = false,
  handleSaveAlertRule,
  onClose,
  getMyEmail,
  isLoading = false,
  alertRules = [],
  filtersOptions = {},
}) => {
  const { appStore = {},  usersStore = {} } = useRootStore() || {};

  let pricingModeLabel = appStore.isPpApplied ? 'Customer' : 'Partner';
  if (appStore.isPpApplied === undefined) {
    pricingModeLabel = 'N/A';
  }

  const alertRuleNames = alertRules
    .filter(({ uuid }) => uuid !== selectedAlertRule?.uuid)
    .map(({ ruleName }) => ruleName);
  const [alertRule, setAlertRule] = React.useState(initiateAlertRule(selectedAlertRule, filtersOptions));
  const [alertRuleValidationErrors, setAlertRuleValidation] = React.useState(null);
  const [saveClicked, setSaveClicked] = React.useState(false);
  const { currencySymbol } = useUserSettingsContext();
  const { getChannels } = useChannels();
  const { data: channels, isLoading: isLoadingChannels } = getChannels();

  useEffect(() => {
    const alertRuleValidation = validateAlertRule(alertRule, alertRuleNames);
    setAlertRuleValidation(alertRuleValidation);
  }, [alertRule]);

  const setEmails = (email) => {
    setAlertRule((prevAlert) => ({ ...prevAlert, email }));
  };
  const setRecipients = (recipients) => {
    setAlertRule((prevAlert) => ({ ...prevAlert, recipients }));
  };
  const addMyEmail = () => {
    const myEmail = getMyEmail();
    if (!alertRule.email.length) {
      setEmails(myEmail);
      return;
    }
    const email = alertRule.email.split(',').map((currentEmails) => currentEmails.trim());
    if (!email.some((currentEmails) => currentEmails === myEmail.trim())) {
      email.push(myEmail);
      setEmails(email.join(', '));
    }
  };

  const handleSetAlertRule = (field, value) => {
    setAlertRule((prevAlertRule) => ({ ...prevAlertRule, [field]: value }));
  };
  const handleSetAlertRuleNumberValues = (field, value) => {
    if (!Number.isNaN(+value)) {
      setAlertRule((prevAlertRule) => ({ ...prevAlertRule, [field]: value }));
    }
  };

  const onSave = async () => {
    setSaveClicked(true);
    if (alertRuleValidationErrors) {
      return;
    }
    const preparedAlertRule = prepareAlertRule(alertRule);
    await handleSaveAlertRule(preparedAlertRule);
  };

  const setFilters = (filterType, field, val) => {
    setAlertRule(({ filters: { [filterType]: oldFilterType, ...restFilters }, ...restAlertRule }) => ({
      ...restAlertRule,
      filters: { ...restFilters, [filterType]: { ...oldFilterType, [field]: val } },
    }));
  };
  const automationPostfix = 'addAlertRule';
  const showErrors = Boolean(saveClicked && alertRuleValidationErrors);

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title={`${selectedAlertRule ? 'Edit' : 'New'} Alert Rule`}
      saveTitle="Save"
      onSave={onSave}
      saveDisabled={isLoading || showErrors}
      closeOnSave={false}
      className={styles.bigModal}
      automationId={`${automationPostfix}Modal`}
    >
      <Form className={classNames(styles.twoColumns)} automation-id={`form${automationPostfix}`}>
        <div>
          <ColoredBlock className="mb-3" icon="memo" color="blue">
            <div className={styles.fieldWrapper} automation-id={`rule-name-wrapper${automationPostfix}`}>
              <h5>Alert Rule Name</h5>
              <Input
                className={classNames(styles.alertsInput, "w-100")}
                value={alertRule.ruleName}
                onChange={(e) => handleSetAlertRule("ruleName", e.target.value)}
                invalid={showErrors && !!alertRuleValidationErrors.ruleName}
                automation-id="alert-rule-name-input"
                invalidComponent={
                  <ErrorMessage
                    displayError={showErrors && !!alertRuleValidationErrors.ruleName}
                    errMsg={alertRuleValidationErrors && alertRuleValidationErrors.ruleName}
                    automationId="alert-rule-name-input-error"
                  />
                }
                placeholder="Alert Rule Name"
                type="text"
              />
              <ErrorMessage
                displayError={saveClicked && alertRuleValidationErrors && !!alertRuleValidationErrors.ruleName}
                errMsg={alertRuleValidationErrors && alertRuleValidationErrors.ruleName}
                automationId="alert-rule-name-input-error"
              />
            </div>
          </ColoredBlock>
          <ColoredBlock className="mb-3" icon="arrowsTurnToDots" color="lilach">
            <div className={styles.deltaRow}>
              <div className={styles.fieldWrapper} automation-id={`min-impact-field-wrapper${automationPostfix}`}>
                <h5>
                  <span>Min Cost Impact ({currencySymbol})</span>
                  <Tooltip placement="top" title={minCostImpactTooltip} arrow>
                    <InfoComponent />
                  </Tooltip>
                </h5>
                <Input
                  className={styles.alertsInput}
                  value={alertRule.minAmountChange}
                  onChange={(e) => handleSetAlertRuleNumberValues('minAmountChange', e.target.value)}
                  invalid={showErrors && !!alertRuleValidationErrors.minImpact}
                  placeholder="1000"
                  type="number"
                  automation-id="min-impact-cost-input"
                  min={50}
                />
              </div>
              <div className={styles.logicalBox}>AND</div>
              <div className={styles.fieldWrapper} automation-id={`min-delta-field-wrapper${automationPostfix}`}>
                <h5>
                  <span>Min. Cost delta (%)</span>
                  <Tooltip placement="top" title={minCostDeltaTooltip} arrow>
                    <InfoComponent />
                  </Tooltip>
                </h5>
                <Input
                  className={styles.alertsInput}
                  value={alertRule.minPercentChange}
                  onChange={(e) => handleSetAlertRuleNumberValues('minPercentChange', e.target.value)}
                  invalid={showErrors && !!alertRuleValidationErrors.minImpact}
                  placeholder="1000"
                  type="number"
                  automation-id="min-impact-percent-input"
                />
              </div>
              <ErrorMessage
                displayError={showErrors && !!alertRuleValidationErrors.minImpact}
                errMsg={alertRuleValidationErrors && alertRuleValidationErrors.minImpact}
                automationId="min-impact-input-error"
              />
            </div>
            <div>Enter either Cost impact $ amount, Cost delta % amount, or both</div>
            <div className={styles.deltaRow} style={{ margin: '16px 0' }}>
              <div className={styles.line} />
              <div className={styles.logicalBox} style={{ margin: 0 }}>
                OR
              </div>
              <div className={styles.line} />
            </div>
            <div className={styles.deltaRow}>
              <div className={styles.fieldWrapper} automation-id={`daily-impact-field-wrapper${automationPostfix}`}>
                <h5>
                  <span>Min. Daily Cost Impact ({currencySymbol})</span>
                  <Tooltip placement="top" title={minDailyCostImpactTooltip} arrow>
                    <InfoComponent />
                  </Tooltip>
                </h5>
                <Input
                  automation-id={`for-number-field${automationPostfix}`}
                  value={alertRule.minAmountDailyChange}
                  min={0}
                  margin="normal"
                  variant="outlined"
                  placeholder="0"
                  className={styles.alertsInput}
                  type="number"
                  invalid={showErrors && !!alertRuleValidationErrors.minAmountDailyChange}
                  onChange={(e) => handleSetAlertRuleNumberValues?.('minAmountDailyChange', e.target.value)}
                />
              </div>
              <div className={styles.logicalBox}>AND</div>
              <div className={styles.fieldWrapper} automation-id={`daily-delta-field-wrapper${automationPostfix}`}>
                <h5><span>Min. Daily Cost delta (%)</span>
                  <Tooltip placement="top" title={minDailyCostDeltaTooltip} arrow>
                  <InfoComponent />
                </Tooltip>
                </h5>
                <Input
                  automation-id={`for-number-field${automationPostfix}`}
                  value={alertRule.minPercentDailyChange}
                  min={0}
                  margin="normal"
                  variant="outlined"
                  placeholder="0"
                  className={styles.alertsInput}
                  type="number"
                  invalid={showErrors && !!alertRuleValidationErrors.minPercentDailyChange}
                  onChange={(e) => handleSetAlertRuleNumberValues?.('minPercentDailyChange', e.target.value)}
                />
              </div>
            </div>
            <div>Represents the difference between the anomalous data point and the expected value for that day</div>
          </ColoredBlock>
          <ColoredBlock className={styles.unsetContentOverflowY} icon="envelope" color="eucaliptus">
            <div automation-id={`recipient-field-wrapper${automationPostfix}`}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex">
                  <h5>Recipients</h5>
                </div>
                <Button
                  type="button"
                  text="My Email"
                  onClick={addMyEmail}
                  overrideStyles={{
                    backgroundColor: 'transparent',
                    color: '#2671FF',
                    fontSize: '12px',
                    padding: 0,
                    height: 19,
                  }}
                  disabled={alertRule.email?.split(',').some((email) => email.trim() === getMyEmail().trim())}
                />
              </div>
              <Input
                className={styles.alertsInput}
                value={alertRule.email}
                onChange={(e) => setEmails(e.target.value)}
                invalid={showErrors && !!alertRuleValidationErrors.email}
                placeholder="example@gmail.com, example@gmail.com "
                name="email"
                automation-id="recipient-emails-input"
              />
              <RecipientsSelect
                className={styles.recipientsSelect}
                channels={channels}
                isLoading={isLoadingChannels}
                savedRecipients={alertRule.recipients}
                onChangeHandler={setRecipients}
                menuPlacement={'top'}
              />
              <ErrorMessage
                displayError={showErrors && !!alertRuleValidationErrors.email}
                errMsg={alertRuleValidationErrors && alertRuleValidationErrors.email}
              />
            </div>
          </ColoredBlock>
        </div>
        <div className="position-relative">
          <ColoredBlock
            icon="filters"
            color="marine"
            scrollable
            style={{ position: 'absolute', height: '100%', width: '100%' }}
          >
            <AlertRuleFilters
              className={styles.newAlertsFilters}
              alertRule={alertRule}
              setFilters={setFilters}
              filtersOptions={filtersOptions}
            />
            {usersStore.isCurrentUserReseller && (<div className={classNames(styles.fieldWrapper, 'mb-2 mt-2')}>
              <h5>
                <span>Pricing Mode</span>
                <Tooltip placement="top" title={pricingModeTooltip} arrow>
                  <InfoComponent />
                </Tooltip>
              </h5>
              <Input
                automation-id={`pricing-mode-field${automationPostfix}`}
                value={pricingModeLabel}
                margin="normal"
                variant="outlined"
                className={styles.alertsInput}
                disabled
              />
            </div>)
            }
          </ColoredBlock>
        </div>
      </Form>
    </CustomModal>
  );
};

AddAlertRuleModal.propTypes = propTypes;

export default AddAlertRuleModal;
