import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import useDataAccessAccounts from 'users/new-user-management/hooks/reactQuery/useDataAccessAccounts';
import Spinner from 'shared/components/andtComponents/Spinner';
import Input from 'shared/components/andtComponents/Input';
import LinkedAccountsComponentWrapper from 'users/containers/Organization/components/LinkedAccounts/LinkedAccountsComponentWrapper';
import {
  COST_CENTER_LINKED_ACCOUNTS_COLUMNS,
  COST_CENTER_PAYER_ACCOUNTS_COLUMNS,
  ENTITIES,
} from 'users/containers/Organization/consts';
import { getInitialAccountsDataWithCounters } from 'users/containers/Organization/components/LinkedAccounts/dataAccessHelperFunctions';
import {
  getAssignableAccounts,
  getCostCenterAccountsData,
} from 'users/containers/Organization/CostCenters/costCentersHelperFunctions';

import styles from './CreateEditCostCenterModal.module.scss';

const CreateEditCostCenterModal = ({ isOpen, setIsOpen, costCenter, parentCcId = null }) => {
  const [saveClicked] = useState(false);
  const [selection, setSelection] = useState(undefined);

  const {
    fetchDataAccessPayerAccounts,
    fetchCostCenterUnassignedAccounts,
    createDataAccessCostCenter,
    updateDataAccessCostCenter,
  } = useDataAccessAccounts();

  const { data: accountsData, isLoading: isAccountsDataLoading } = fetchDataAccessPayerAccounts();
  const { data: unassignedAccountsData, isLoading: isUnassignedAccountsDataLoading } =
    fetchCostCenterUnassignedAccounts();

  // If costCenterId is not provided, then it's a new cost center and need to generate id as Guid
  const [currentCostCenter, setCurrentCostCenter] = useState({
    id: costCenter?.id || uuidv4(),
    name: costCenter?.name || '',
    externalId: costCenter?.externalId || '',
    parentCcId: parentCcId,
  });

  const accountsDataAllowedToAssign = useMemo(() => {
    if (!accountsData || !unassignedAccountsData) {
      return null;
    }
    const unassignedAccounts = getAssignableAccounts(accountsData, unassignedAccountsData, costCenter?.accounts);
    return unassignedAccounts;
  }, [accountsData, costCenter?.accounts, unassignedAccountsData]);

  const dataToDisplay = useMemo(() => {
    if (!accountsDataAllowedToAssign || !costCenter?.accounts) {
      return null;
    }
    const costCenterAccountsDataTmp = getCostCenterAccountsData(
      accountsDataAllowedToAssign,
      costCenter?.accounts,
      true,
    );
    const combinedData = getInitialAccountsDataWithCounters(accountsDataAllowedToAssign);

    return { ...combinedData, accountsData: costCenterAccountsDataTmp };
  }, [accountsDataAllowedToAssign, costCenter?.accounts]);

  const convertPayerWithLinkedAccountsPayload = (selectedPayerWithLinkedAccounts) => {
    const payers = [];
    Object.keys(selectedPayerWithLinkedAccounts)?.forEach((payerAccountId) => {
      const payerAccount = {
        payerAccountId: payerAccountId,
        linkedAccountIds: selectedPayerWithLinkedAccounts[payerAccountId],
      };
      payers.push(payerAccount);
    });
    return payers?.length > 0 ? payers : null;
  };

  const handleCostCenterAccountsSelectionChange = (selected) => {
    if (selected) {
      const renamedObject = {
        accountsPayloadToAdd: {
          payerWithLinkedAccounts: convertPayerWithLinkedAccountsPayload(
            selected?.accountsToAdd?.payerWithLinkedAccounts,
          ),
          fullyAssignedPayerAccountIDs: selected?.accountsToAdd?.fullyAssignedPayerAccounts,
        },
        accountsPayloadToRemove: {
          payerWithLinkedAccounts: convertPayerWithLinkedAccountsPayload(
            selected?.accountsToRemove?.payerWithLinkedAccounts,
          ),
          fullyAssignedPayerAccountIDs: selected?.accountsToRemove?.fullyAssignedPayerAccounts,
        },
      };
      delete renamedObject.accountsToAdd;
      delete renamedObject.accountsToRemove;

      setSelection(renamedObject);
    }
  };

  const onSave = async () => {
    if (costCenter) {
      await updateDataAccessCostCenter.mutateAsync({
        costCenterId: currentCostCenter.id,
        costCenterUpdateData: { name: currentCostCenter.name, ...selection },
      });
    } else {
      await createDataAccessCostCenter.mutateAsync({
        costCenter: currentCostCenter,
        accountsPayload: selection.accountsPayloadToAdd,
      });
    }

    setIsOpen(false);
  };

  const comment = `When assigning a payer account to a Cost Center, all current and future linked
   accounts included in the payer account will be assigned to the Cost Center.`;

  return (
    <CustomModal
      className={styles}
      comment={comment}
      onClose={() => {
        setIsOpen(false);
      }}
      onCloseClick={() => {
        setIsOpen(false);
      }}
      headerMode={costCenter ? MODAL_MODES.EDIT : MODAL_MODES.ADD}
      footerDisabled={isUnassignedAccountsDataLoading || isAccountsDataLoading || saveClicked}
      onSave={onSave}
      open={isOpen}
      automationId="create-edit-cost-center-modal"
      saveTitle={costCenter ? 'Save' : 'Add'}
      saveDisabled={!currentCostCenter.name}
      title={`${costCenter ? 'Edit' : 'Add'} Cost Center`}
    >
      <>
        <div className={styles.costCenterDetails}>
          <div className={styles.inputContainer}>
            <div className={styles.inputLabel}>
              <label htmlFor="cost-center-name">Cost Center Name</label>
              <label className={styles.inputComment} htmlFor="cost-center-name">
                Alphanumeric characters only
              </label>
            </div>
            <Input
              id="cost-center-name"
              value={currentCostCenter.name}
              onChange={(e) => {
                setCurrentCostCenter((prev) => ({ ...prev, name: e?.target?.value }));
              }}
              placeholder="Enter Name"
            />
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.inputLabel}>
              <label htmlFor="cost-center-external-id">Cost Center Code</label>
            </div>
            <Input
              id="cost-center-external-id-input"
              value={currentCostCenter.externalId}
              onChange={(e) => setCurrentCostCenter((prev) => ({ ...prev, externalId: e?.target?.value }))}
              placeholder="Enter Code"
            />
          </div>
        </div>
        {isAccountsDataLoading || isUnassignedAccountsDataLoading ? (
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
        ) : (
          <LinkedAccountsComponentWrapper
            additionalData={{ costCenterId: currentCostCenter.id }}
            allAccountsData={dataToDisplay}
            entity={ENTITIES.COST_CENTER.id}
            isCreateEditMode={true}
            linkedAccountsColumns={Object.values(COST_CENTER_LINKED_ACCOUNTS_COLUMNS).map((c) => c.columnName)}
            onSelectionChange={handleCostCenterAccountsSelectionChange}
            payerAccountsColumns={Object.values(COST_CENTER_PAYER_ACCOUNTS_COLUMNS).map((c) => c.columnName)}
          />
        )}
      </>
    </CustomModal>
  );
};

CreateEditCostCenterModal.propTypes = {
  costCenter: PropTypes.shape({
    accounts: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    externalId: PropTypes.string,
    parentCcId: PropTypes.string,
  }),
  isOpen: PropTypes.bool.isRequired,
  parentCcId: PropTypes.string,
  setIsOpen: PropTypes.func.isRequired,
};

export default CreateEditCostCenterModal;
