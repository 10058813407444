import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import {
  DataTypeProvider,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SearchState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { cloneDeep } from 'lodash';
import { Grid, PagingPanel, SearchPanel, TableHeaderRow, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { Col, Row } from 'reactstrap';
import Checkbox from '@mui/material/Checkbox';
import { blue } from '@mui/material/colors';
import { Action, HierarchicalEntityCategory } from '@anodot-cost/rbac-client';
import { mapGroupByAttributeToCostUsageState } from 'usage/constants/costAndUsageConstants';
import HideWarningModal from 'shared/components/DeleteWarningModal';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import Spinner from 'shared/components/andtComponents/Spinner';
import TableWrapper from 'shared/components/tables/TableWrapper';
import AddPanelsToDashboardModal from 'shared/components/NewCustomDashboardPanelModal';
import { Routes } from 'shared/constants/routes';
import Chip from 'shared/components/andtComponents/Chip';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import CustomDashboardsPanelsActionButton from './CustomDashboardsPanelsActionButton';
import * as Constants from '../constants/customDashboardsPanelsConstants';
import { PANEL_COMPONENT_TYPES } from '../../CustomDashboard/constants/customDashboardConstants';
import * as custDabrdHelpers from '../../CostAndUsageExplorer/helpers/customDashboardHelperMethods';

// if createdBy === null - it means it is pre-defined panel
const compareCreatedByField = (a, b) => (a || 'System').localeCompare(b || 'System');
// if createdBy === null - it means it is pre-defined panel
const filterCreatedByField = (value, filter) =>
  (value || 'System').toLowerCase().includes((filter.value || '').toLowerCase());

const createByFormatter = (data) => data.row.createdBy || <Chip label="System" color={blue[300]} size="small" />;
class CustomDashboardsPanelsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: Constants.CUSTOM_DASHBOARDS_PANELS_TABLE_COLUMNS,
      tableColumnExtensions: Constants.CUSTOM_DASHBOARDS_PANELS_TABLE_COLUMN_EXTENSIONS,
      isLoading: false,
      isAddPanelToDashboardModalOpen: false,
      selectedPanels: [],
      panelSelectedFoHide: null,
      pageSizes: [10, 15, 20, 0],
    };
  }

  getMagnifierGlassPathname = (panelType, isK8S) => {
    if (panelType.includes('assets')) {
      if (isK8S) {
        return Routes.K8S_ASSETS;
      }
      return Routes.ASSETS;
    }
    if (panelType.includes('k8s')) {
      return Routes.K8S_USAGE;
    }
    if (panelType === 'recomm-cloud-waste' || panelType === PANEL_COMPONENT_TYPES.RECOMM_SUMMERY_TABLE) {
      return Routes.RECOMMENDATION_EXPLORER;
    }
    if (
      [
        PANEL_COMPONENT_TYPES.HEAT_MAP,
        PANEL_COMPONENT_TYPES.SAVING_OPPORTUNITIES,
        PANEL_COMPONENT_TYPES.CLOUD_WASTE,
      ].includes(panelType)
    ) {
      return Routes.RECOMMENDATION_EXPLORER;
    }
    return Routes.COST_USAGE_EXPLORER;
  };

  getCurrDataStateByMainGroupBy = (state, mainGroupBy) => {
    if (state) {
      return state.currDataState;
    }
    return mapGroupByAttributeToCostUsageState.get(mainGroupBy) || 0;
  };

  handleAddPanelToDashboard = (panel) => {
    if (!this.state.selectedPanels.find((currPanel) => currPanel === panel.uuid)) {
      this.setState({ selectedPanels: [panel] });
    }
    this.setState({ isAddPanelToDashboardModalOpen: true });
  };
  handleCloseAddPanelsToDashboardModal = () => {
    this.setState({ isAddPanelToDashboardModalOpen: false });
  };

  handleHidePanelClicked = (panel) => {
    this.setState({ panelSelectedFoHide: panel });
  };

  handleShowPanelClicked = (panel) => {
    const {
      usageStore: {
        rootStore: { invoiceStore },
      },
    } = this.props;
    const { type, routeParams, fetchParamsPrepFunc, state, uuid } = panel;
    // fix for old panels without isPublicCost in route params
    // https://anodot.atlassian.net/browse/PIL-6490
    routeParams.isPublicCost = routeParams.isPublicCost === undefined ? state?.isPublicCost : routeParams.isPublicCost;
    const paramsForFetchFunction = fetchParamsPrepFunc(routeParams, undefined, uuid, '');
    const currDataState = this.getCurrDataStateByMainGroupBy(state, routeParams.groupByLevel);
    if (!type.includes('asset')) {
      invoiceStore.fetchDataByState(currDataState, ...paramsForFetchFunction);
    }
  };

  panelActionButtonHandlers = {
    addPanelToDashboard: this.handleAddPanelToDashboard,
    hidePanel: this.handleHidePanelClicked,
    showPanel: this.handleShowPanelClicked,
  };

  handleHidePanel = async (action) => {
    if (action === 'cancel') {
      this.setState({ panelSelectedFoHide: null });
    } else if (action === 'delete') {
      try {
        const panel = cloneDeep(this.state.panelSelectedFoHide);
        this.setState({ isLoading: true });
        await this.props.usageStore.customDbSubStore.customDashboardModel.hidePanel(panel);
        this.setState({ panelSelectedFoHide: null, isLoading: false });
      } catch {
        this.setState({ panelSelectedFoHide: null, isLoading: false });
      }
    }
  };

  isMagnifierAvailable = (type, cloudAccountId) => {
    const { getCurrentDisplayedAccountId, currDispUserAccountKey } = this.props.usersStore;
    if (type === 'budget-chart') {
      return false;
    }
    return !type.includes('kpis') && cloudAccountId === getCurrentDisplayedAccountId(currDispUserAccountKey);
  };

  handleChangeSelectedPanels = (event, data) => {
    if (event.target.checked) {
      this.setState((state) => state.selectedPanels.push(data.row));
    } else {
      this.setState(
        (state) => (state.selectedPanels = state.selectedPanels.filter((panel) => panel.uuid !== data.row.uuid)),
      );
    }
  };

  render() {
    const {
      usageStore,
      usersStore,
      usageStore: {
        customDbSubStore: { customDashboardModel },
      },
    } = this.props;
    const {
      columns,
      tableColumnExtensions,
      panelSelectedFoHide,
      isAddPanelToDashboardModalOpen,
      isLoading,
      selectedPanels,
      pageSizes,
    } = this.state;
    const { getCurrentDisplayedAccountId, currDispUserAccountKey } = usersStore;
    const currDispUserCloudAccId = getCurrentDisplayedAccountId(currDispUserAccountKey);
    const { displayPanels, modelIsLoading, existingDashboardsNamesAndIds } = customDashboardModel;
    const userActionsFormatter = (data) => (
      <ReadOnlyDisplayWrapper category={HierarchicalEntityCategory.Dashboards} action={Action.Update}>
        <CustomDashboardsPanelsActionButton
          panel={data.row}
          panelHandlers={this.panelActionButtonHandlers}
          currDispUserCloudAccId={currDispUserCloudAccId}
          customDashboardModel={usageStore.customDbSubStore.customDashboardModel}
          isMagnifierAvailable={this.isMagnifierAvailable(data.row.type, data.row.accountId)}
          getMagnifierPath={this.getMagnifierGlassPathname}
        />
      </ReadOnlyDisplayWrapper>
    );
    const selectCheckboxFormatter = (data) => (
      <ReadOnlyDisplayWrapper category={HierarchicalEntityCategory.Dashboards} action={Action.Update}>
        <Checkbox
          color="primary"
          checked={selectedPanels.find((panel) => panel === data.row.uuid)}
          onChange={(e) => {
            this.handleChangeSelectedPanels(e, data);
          }}
        />
      </ReadOnlyDisplayWrapper>
    );
    if (isLoading || modelIsLoading) {
      return <Spinner />;
    }
    return (
      <Row style={{ width: '100%' }}>
        <Col xs={12} md={12} lg={12} xl={12}>
          <Paper>
            <Grid rows={displayPanels} columns={columns}>
              <SortingState />
              <PagingState defaultCurrentPage={0} defaultPageSize={pageSizes[2]} />
              <SearchState />
              <IntegratedFiltering columnExtensions={[{ columnName: 'createdBy', predicate: filterCreatedByField }]} />
              <IntegratedSorting columnExtensions={[{ columnName: 'createdBy', compare: compareCreatedByField }]} />
              <IntegratedPaging />
              <DataTypeProvider for={['selectPanelCheckbox']} formatterComponent={selectCheckboxFormatter} />
              <DataTypeProvider for={['name']} />
              <DataTypeProvider for={['userAction']} formatterComponent={userActionsFormatter} />
              <DataTypeProvider for={['createdBy']} formatterComponent={createByFormatter} />
              <TableWrapper columnExtensions={tableColumnExtensions} />
              <TableHeaderRow showSortingControls />
              <Toolbar />
              <SearchPanel />
              <PagingPanel pageSizes={pageSizes} />
            </Grid>
          </Paper>
        </Col>
        <HideWarningModal
          isOpen={panelSelectedFoHide}
          handleDelete={this.handleHidePanel}
          modalTitle="Delete Panel"
          warningMessage={`Be advised, You are about to delete ${
            (panelSelectedFoHide && panelSelectedFoHide.name) || ''
          } Panel from the Panels table`}
          executeButtonTitle="Delete"
        />
        {isAddPanelToDashboardModalOpen && (
          <AddPanelsToDashboardModal
            modalIsOpen={isAddPanelToDashboardModalOpen}
            onClose={this.handleCloseAddPanelsToDashboardModal}
            customDashboardStore={usageStore.customDbSubStore}
            existingDashboardsNamesAndIds={existingDashboardsNamesAndIds}
            helpers={custDabrdHelpers}
            selectedPanels={selectedPanels}
            isExistingPanel
            usageStore={usageStore}
          />
        )}
      </Row>
    );
  }
}

const ObserverCustomDashboardsTable = withUserSettingsConsumer(CustomDashboardsPanelsTable);
CustomDashboardsPanelsTable.propTypes = {
  usageStore: PropTypes.object.isRequired,
  usersStore: PropTypes.object.isRequired,
};
export default ObserverCustomDashboardsTable;
