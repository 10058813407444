import { FilterTypes } from 'usage/constants/usageConstants';
import { groupBy, isArray } from 'lodash';
import config from '../../config';
import { getValueFromStorage } from './tokenUtil';

// CUE state has objects instead of maps. this func is used to convert these objects to maps for panels, reports
export function convertObjToMap(obj) {
  if (!obj || obj instanceof Map || Array.isArray(obj)) {
    return obj;
  }
  return new Map(Object.entries(obj));
}

// CUE state has objects instead of maps. this func is used to convert these maps to objects from panels, reports
// accepts array in format [[key, value], [key, value]] or a Map instance
export function convertStringifyMapToObj(mapStr) {
  let array = mapStr;
  if (mapStr instanceof Map) {
    array = [...mapStr.entries()];
  }
  if (!isArray(array)) {
    return array;
  }
  return array.reduce((acc, item) => ({ ...acc, [item[0]]: item[1] }), {});
}

const mapSymbolToQueryString = new Map([
  ['#', '%23'],
  ['&', '%26'],
  ['+', '%2B'],
]);

export const sanitizeParam = (param) =>
  param?.replace(/#|&|\+/g, (symbol) => mapSymbolToQueryString.get(symbol) || symbol) ?? '';

export const buildFilterParams = (filtersMap, filterType = FilterTypes.INCLUDE) => {
  let filterParams = '';
  if (filtersMap && filtersMap.size > 0) {
    filtersMap.forEach((filterValues, filter) => {
      filterValues.forEach((filterValue) => {
        const sanitizedFilterValue = sanitizeParam(encodeURIComponent(filterValue));
        const filterPrefixMap = {
          [FilterTypes.INCLUDE]: '&filters',
          [FilterTypes.EXCLUDE]: '&excludeFilters',
          [FilterTypes.LIKE]: '&likeFilters',
          [FilterTypes.NOT_LIKE]: '&excludeLikeFilters',
        };
        const filterPreFix = filterPrefixMap[filterType];
        filterParams = filterParams.concat(`${filterPreFix}[${filter}]=${sanitizedFilterValue}`);
      });
    });
  }
  return filterParams;
};

export const buildFilterConfigParams = (filterConfigMap) => {
  let filterParams = '';
  if (filterConfigMap && filterConfigMap.size > 0) {
    filterConfigMap.forEach((config, key) => {
      Object.keys(config).forEach((configKey) => {
        const sanitizedFilterValue = sanitizeParam(encodeURIComponent(config[configKey]));
        filterParams = filterParams.concat(`&${configKey}[${key}]=${sanitizedFilterValue}`);
      });
    });
  }
  return filterParams;
};

export const getIncludeExcludeMaps = (filters, excludedFiltersStatusMap, likedFiltersStatus = {}) => {
  const filtersMap = convertObjToMap(filters) || new Map();
  const includeFiltersMap = new Map();
  const excludeFiltersMap = new Map();
  const likeFiltersStatus = {};
  const likeExcludeFiltersStatus = {};
  Array.from(filtersMap.keys()).forEach((filterKey) => {
    const fieldExclude = excludedFiltersStatusMap.get(filterKey);
    const fieldLike = likedFiltersStatus[filterKey];
    const fieldValues = filtersMap.get(filterKey);
    // it means that this is tags/labels filter
    // which should have separate include/exclude tags settings inside this object
    if (typeof fieldExclude === 'object') {
      const filtersGroupedByKey = groupBy(fieldValues, (item) => item.split(': ')[0]);
      Object.keys(filtersGroupedByKey).forEach((key) => {
        if (fieldExclude[key]) {
          excludeFiltersMap.set(filterKey, [...(excludeFiltersMap.get(filterKey) || []), ...filtersGroupedByKey[key]]);
        } else {
          includeFiltersMap.set(filterKey, [...(includeFiltersMap.get(filterKey) || []), ...filtersGroupedByKey[key]]);
        }
      });
    } else if (fieldExclude) {
      if (fieldLike) {
        likeExcludeFiltersStatus[filterKey] = fieldValues;
      } else {
        excludeFiltersMap.set(filterKey, fieldValues);
      }
    } else if (fieldLike) {
      likeFiltersStatus[filterKey] = fieldValues;
    } else {
      includeFiltersMap.set(filterKey, fieldValues);
    }
  });
  return { includeFiltersMap, excludeFiltersMap, likeFiltersStatus, likeExcludeFiltersStatus };
};

export const buildWhereParams = (whereParamsObj) => {
  const whereParamsMap = convertObjToMap(whereParamsObj);
  let whereParams = '';
  if (whereParamsMap && whereParamsMap.size > 0) {
    whereParamsMap.forEach((paramValue, key) => {
      const sanitizedParamValue = sanitizeParam(encodeURIComponent(paramValue));
      whereParams = whereParams.concat(`&wheres[${key}]=${sanitizedParamValue}`);
    });
  }
  return whereParams;
};

export const sanitizeQuery = (query) => query.replace(/#/g, '%23');

const encoderFunc = (valuesObj) => {
  const baseKeysObj = { userkey: null, accountkey: null, divisionId: null };
  let concatKey = '';
  let KeysAndValuesObj = {};
  if (valuesObj) {
    // populate the values, i.e. { userKey: "232weeww", accKey: 1} ==> 232weeww:1
    KeysAndValuesObj = { ...baseKeysObj };
    Object.keys(KeysAndValuesObj).forEach((key) => {
      KeysAndValuesObj[key] = key in valuesObj ? valuesObj[key] : '';
    });
  }
  concatKey = Object.keys(KeysAndValuesObj).reduce(
    (acc, currKey, index) =>
      // createConcatedString
      index === 0 ? acc.concat(KeysAndValuesObj[currKey]) : acc.concat(':', KeysAndValuesObj[currKey]),
    '',
  );
  return concatKey;
};

export const createApiKey = (usersStore) => {
  let currUserKey = -1;
  let defaultUserAccountKey = -1;
  let currDivisionId = -1;
  if (usersStore) {
    if (usersStore.currentDisplayedUserKey) {
      currUserKey = usersStore.currentDisplayedUserKey;
    }
    if (usersStore.currDispUserAccountKey) {
      defaultUserAccountKey = usersStore.currDispUserAccountKey;
    }
    if (usersStore.currDispUserDivisionId !== null || usersStore.currDispUserDivisionId !== undefined) {
      currDivisionId = usersStore.currDispUserDivisionId;
    }
  }
  const userkey = currUserKey;
  const accountkey = defaultUserAccountKey;
  const divisionId = currDivisionId;
  return encoderFunc({ userkey, accountkey, divisionId });
};
const createCommonParams = (appStore) => {
  const commonParams = { ...appStore.getCommonParams() };
  return JSON.stringify(commonParams);
};
export const createCustomHeaders =
  (rootStore, customApiKeyParams = null) =>
  () => {
    const authToken = getValueFromStorage('authToken');
    const { usersStore, appStore } = rootStore;
    let impersonationToken = '';
    if (appStore.isKeyCloakManagement) {
      impersonationToken = getValueFromStorage('impersonationToken');
    }
    let apikey = createApiKey(usersStore);
    const { accountKey, divisionId, userKey } = customApiKeyParams || {};
    apikey = customApiKeyParams ? `${userKey || apikey.split(':')[0]}:${accountKey}:${divisionId}` : apikey;
    const commonParams = createCommonParams(appStore);
    return {
      apikey,
      commonParams,
      Authorization: authToken,
      ['impersonation-token']: impersonationToken,
    };
  };
// func params should be array of params ordered for getDataFunc
export const getDataWithCustomApikey = (rootStore, apikeyParams, getDataFunc, funcParams) => {
  config.apiReqHeaders.setCreateHeadersFunc(createCustomHeaders(rootStore, apikeyParams));
  const data =  Array.isArray(funcParams) ? getDataFunc(...funcParams) : getDataFunc({...funcParams});
  config.apiReqHeaders.setCreateHeadersFunc(createCustomHeaders(rootStore));
  return Promise.resolve(data);
};

export function querystring(name, url = window.location.href) {
  name = name.replace(/[[]]/g, '\\$&');

  const regex = new RegExp(`[?&]${name}(=([^#]*)|#|$)`, 'i');
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
