import React from 'react';
import PropType from 'prop-types';
import classNames from 'classnames';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { ACCOUNT_TYPES, ENTITIES } from 'users/containers/Organization/consts.js';
import { prepareLinkAccountLabel } from 'recommendations/containers/Dashboard/helpers/dataPrepareHelpers.js';

import styles from './NoAccounts.module.scss';

const NoAccounts = ({ colSpan, type, cloudProviderId, entity }) => {
  const name = type === ACCOUNT_TYPES.PAYER_ACCOUNT ? 'payer account' : `${prepareLinkAccountLabel(+cloudProviderId)}`;
  const entityName = Object.values(ENTITIES).find((e) => e.id === entity)?.label;

  return (
    <td
      colSpan={colSpan}
      className={classNames('border-bottom-0 border-start-0 border-end-0 border-top-0', styles.container)}
    >
      <div className={styles.accountsEmptyStateContainer}>
        <GenerateIcon iconName={ICONS.cloudMinus.name} className={styles.emptyStateIcon} />
        <div className={styles.emptyStateText}>{`No ${name} for this ${entityName}`}</div>
      </div>
    </td>
  );
};

NoAccounts.propTypes = {
  cloudProviderId: PropType.string,
  colSpan: PropType.number,
  entity: PropType.string,
  type: PropType.string,
};

export default NoAccounts;
